import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  message,
  notification,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Upload, { RcFile } from 'antd/lib/upload';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import FormSelect from '../../components/FormSelect';
import LayoutFull from '../../components/LayoutFull';
import { useAuth } from '../../contexts/auth.context';
import api from '../../services/api.service';
import { formatCepMask } from '../../utils/masks';
import { onlyNumbers } from '../../utils/sanitize';

type FormFilter = {
  social_name: string;
  document: number;
  active: number;
  group_account_plan_id: number;
  regime_tributario: string;
};

const RegimeTributario: Record<string, string> = {
  'lucro_real': 'Lucro Real',
  'simples_nacional': 'Simples Nacional',
  'lucro_presumido': 'Lucro Presumido',
  'isento': 'Isento',
}

export function Company() {

  const { user, setCompany } = useAuth();

  const [form] = Form.useForm();
  const [formFilter] = Form.useForm();
  const [formCompanyService] = Form.useForm();
  const [formCompanyCnae] = Form.useForm();
  const [formCompanyTax] = Form.useForm();
  const { Option } = Select;

  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [companySelectedId, setCompanySelectedId] = useState<number>();

  const [accountPlanList, setAccountPlanList] = useState<any[]>([]);
  const [groupAccountPlanList, setGroupAccountPlanList] = useState<any[]>([]);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [cityList, setCityList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState({
    visible: false,
    url: '',
    modalVisible: false,
  });
  const [fileImage, setFileImage] = useState<any>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [modalPhotoVisible, setModalPhotoVisible] = useState<boolean>(false);
  const [modalPhotoUrl, setModalPhotoUrl] = useState<string>('');
  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [serviceList, setServiceList] = useState([]);
  const [companyServiceList, setCompanyServiceList] = useState([]);

  const [cnaeList, setCnaeList] = useState([]);
  const [companyCnaeList, setCompanyCnaeList] = useState([]);

  const [taxList, setTaxList] = useState([]);
  const [companyTaxList, setCompanyTaxList] = useState([]);

  const columns: any[] = [
    {
      title: 'Código',
      key: 'id',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.company_id.length - b.company_id.length,
      render: (value: number, record: any) => record.company_id
    },
    {
      title: 'Logo',
      key: 'avatar',
      dataIndex: 'avatar',
      render: (value: string, record: any) => (
        <img
          src={process.env.REACT_APP_AVATAR_URL + record?.company?.avatar}
          alt="Ilustração"
          width="90"
          height="90"
          onClick={() => handlerImagePreview(record?.company?.avatar)}
        />
      ),
    },
    {
      title: 'Razão Social',
      key: 'social_name',
      dataIndex: 'social_name',
      sorter: (a: any, b: any) => a?.company?.social_name.length - b?.company?.social_name.length,
      render: (value: string, record: any) => (
        <div>
          <strong>{record?.company?.social_name}</strong>
          <br />
          <p>
            <small style={{ fontWeight: 'bolder' }}>CPF/CNPJ: </small>
            {record?.company?.document}
          </p>
        </div>
      ),
    },
    {
      title: 'Responsável',
      key: 'responsible',
      dataIndex: 'responsible',
      sorter: (a: any, b: any) => a?.company?.responsible.length - b?.company?.responsible.length,
      render: (value: string, record: any) => record?.company?.responsible,
    },
    {
      title: 'Segmento',
      key: 'group_account_plan_id',
      dataIndex: 'group_account_plan_id',
      render: (value: string, record: any) => record?.company?.group_account_plan?.description,
    },
    {
      title: 'Regime tributário',
      key: 'regime_tributario',
      dataIndex: 'regime_tributario',
      sorter: (a: any, b: any) =>
        a?.company?.regime_tributario.length - b?.company?.regime_tributario.length,
      render: (value: string, record: any) => RegimeTributario[record?.company?.regime_tributario],
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (value: boolean, record: any) => (
        <Switch
          size="small"
          defaultChecked={Boolean(record?.company?.active)}
          onClick={(value) => handleToggleActive(record?.company?.id, +value)}
        />
      ),
      sorter: (a: any, b: any) => a?.company?.active - b?.company?.active,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record?.company?.id)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage } };
      const { data } = await api.get('organization', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    setCurrentPage(1);
    setFilters({
      social_name: values.social_name || undefined,
      document: values.document || undefined,
      active: values?.active,
      group_account_plan_id: values.group_account_plan_id || undefined,
      regime_tributario: values.regime_tributario || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    async (id: number) => {
      setCompanySelectedId(id)
      const { data } = await api.get(`company/${id}`);

      form.setFieldsValue(data);
      if (data.avatar) {
        const avatar: UploadFile = {
          uid: '1',
          name: data.avatar,
          status: 'done',
          url: `${process.env.REACT_APP_AVATAR_URL}${data.avatar}`,
        };
        setFileList([avatar]);
      }

      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);

      getCompanyServices();
      getCompanyCnaes();
      getCompanyTaxes();
    },
    [form],
  );

  const handleToggleActive = async (id: string, active: number) => {
    try {
      await api.put(`company/${id}`, { active });
      message.success('Registro atualizado com sucesso');
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`company/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const saveOrCreate = async (values: any) => {
    setLoading(true);

    const formData = new FormData();

    if (values.account_cashier) {
      formData.append('account_cashier', values.account_cashier);
    }

    if (values.account_bank) {
      formData.append('account_bank', values.account_bank);
    }

    if (values.matriz) {
      formData.append('matriz', values.matriz);
    }

    if (values.city_id) {
      formData.append('city_id', values.city_id);
    }

    formData.append('fantasy_name', values.fantasy_name);
    formData.append('social_name', values.social_name);
    formData.append('responsible', values.responsible);
    formData.append('document', onlyNumbers(values.document));
    formData.append('regime_tributario', values.regime_tributario);
    formData.append('group_account_plan_id', values.group_account_plan_id);
    formData.append('telphone', values.telphone);
    formData.append('cellphone', values.cellphone);
    formData.append('postal_code', values.postal_code);
    formData.append('street', values.street);
    formData.append('district', values.district);
    formData.append('number', values.number);
    formData.append('email', values.email);
    formData.append('active', values.active);
    formData.append('is_matriz', values.is_matriz);
    formData.append('about', values.about);
    formData.append('integration_fiscal_integranotas_token', values.integration_fiscal_integranotas_token);
    formData.append('nfse_last_number', values.nfse_last_number);

    if (fileImage) {
      formData.append('photo', fileImage);
    }

    if (values.id) {
      try {
        await api.put(`company/${values.id}`, formData);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const { data } = await api.post('company', formData);
        if (!dataTable.length) {
          setCompany(data.id);
          location.reload();
        }
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchAccountPlan = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('account-plan', {
        params: { name: search, company_id: user?.company_default_id },
      });
      setAccountPlanList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchGroupAccountPlan = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('group-account-plan', {
        params: { name: search },
      });
      setGroupAccountPlanList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchCompanies = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleClosePreviewImage = () => setPreviewImage({ ...previewImage, modalVisible: false });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleOpenPreviewImage = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage({
      ...previewImage,
      modalVisible: true,
      url: file.url || (file.preview as string),
    });
  };

  const handleChange: UploadProps['onChange'] = ({ fileList }) => {
    const file = fileList[0];
    if (file.size && file.type) {
      const typesAvailabled = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type);
      const size = file.size / 1024 / 1024 < 1;

      if (!typesAvailabled) {
        notification.error({
          message: 'Envie somente imagens do tipo: PNG, JPG ou JPEG',
        });
        return;
      }

      if (!size) {
        notification.error({
          message: 'O arquivo enviado é maior que 1MB',
        });
        return;
      }
      setFileList([file]);
    }
  };

  const handleUpload = (file: RcFile): boolean => {
    setFileImage(file);
    return false;
  };

  const handlerImagePreview = (imageUrl: string) => {
    setModalPhotoVisible(true);
    setModalPhotoUrl(process.env.REACT_APP_AVATAR_URL + imageUrl);
  };

  const searchCity = async (search: string) => {
    try {
      if (!search) return;
      setLoading(true);
      const { data } = await api.get('cities', {
        params: { description: search },
      });

      setCityList(data)

    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchServices = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('services', {
        params: { description: search },
      });
      setServiceList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  const getCompanyServices = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('company-services', {
        params: { company_id: companySelectedId },
      });
      setCompanyServiceList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  const saveCompanyService = async (data: any) => {
    try {
      setLoading(true);
      await api.post('company-services', { company_id: companySelectedId, ...data });
      getCompanyServices()
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }
  const removeCompanyService = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await api.delete(`company-services/${id}`);
      getCompanyServices()
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchCnaes = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('cnaes', {
        params: { description: search },
      });
      setCnaeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  const getCompanyCnaes = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('company-cnaes', {
        params: { company_id: companySelectedId },
      });
      setCompanyCnaeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  const saveCompanyCnae = async (data: any) => {
    try {
      setLoading(true);
      await api.post('company-cnaes', { company_id: companySelectedId, ...data });
      getCompanyCnaes()
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }
  const removeCompanyCnae = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await api.delete(`company-cnaes/${id}`);
      getCompanyCnaes()
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchTaxes = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('taxes', {
        params: { description: search },
      });
      setTaxList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  const getCompanyTaxes = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('company-taxes', {
        params: { company_id: companySelectedId },
      });
      setCompanyTaxList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  const saveCompanyTax = async (data: any) => {
    try {
      setLoading(true);
      await api.post('company-taxes', { company_id: companySelectedId, ...data });
      getCompanyTaxes()
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }
  const removeCompanyTax = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await api.delete(`company-taxes/${id}`);
      getCompanyTaxes()
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Cadastro de empresas">
      <Form
        onFinish={handlerFilter}
        onFinishFailed={() => { }}
        autoComplete="off"
        layout="vertical"
        form={formFilter}>
        <Row gutter={15}>
          <Col span={2}>
            <Form.Item name="id" label="Código">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="social_name" label="Nome">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="document" label="CPF/CNPJ">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="active" label="Ativo">
              <Select placeholder="Ativo" allowClear>
                <Option value="1">Ativos</Option>
                <Option value="0">Inativos</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="regime_tributario" label="Regime Tributário">
              <Select placeholder="Regime Tributário" allowClear>
                <Option value="simples_nacional">Simples Nacional</Option>
                <Option value="lucro_real">Lucro Real</Option>
                <Option value="lucro_presumido">Lucro Presumido</Option>
                <Option value="isento">Isento</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="group_account_plan_id" label="Segmento">
              <Select
                labelInValue={false}
                placeholder="Digite para buscar..."
                notFoundContent={
                  !groupAccountPlanList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                }
                filterOption={false}
                onSearch={(value) => searchGroupAccountPlan(value)}
                style={{ width: '100%' }}
                showSearch
                allowClear>
                {groupAccountPlanList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.code} - {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={"80%"}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Tabs
          defaultActiveKey="data"
          style={{ marginBottom: 32 }}
        >
          <Tabs.TabPane tab="Dados cadastrais" key="data">
            <Form
              layout="vertical"
              form={form}
              autoComplete="off"
              onFinish={saveOrCreate}
              initialValues={{
                id: '',
                type: 'PF',
              }}>
              <Row gutter={15}>
                <Form.Item name="id" style={{ display: 'none' }}>
                  <Input readOnly name="id" />
                </Form.Item>
                <Col span={12}>
                  <Form.Item name="fantasy_name" label="Nome fantasia" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="social_name" label="Razão Social" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="responsible"
                    label="Nome do responsável"
                    rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="document" label="CNPJ" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="regime_tributario"
                    label="Regime Tributário"
                    rules={[{ required: true }]}>
                    <Select placeholder="Regime Tributário">
                      <Option value="simples_nacional">Simples Nacional</Option>
                      <Option value="lucro_real">Lucro Real</Option>
                      <Option value="lucro_presumido">Lucro Presumido</Option>
                      <Option value="isento">Isento</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="group_account_plan_id" label="Segmento" rules={[{ required: true }]}>
                    <FormSelect
                      onSearch={(value: string) => searchGroupAccountPlan(value)}
                      defaultValue={form.getFieldValue('group_account_plan')?.description}
                      field={form.getFieldValue('group_account_plan')}
                      items={groupAccountPlanList}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="telphone" label="Telefone Fixo">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="cellphone" label="Celular">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="postal_code" label="CEP">
                    <Input
                      maxLength={9}
                      onChange={(e) =>
                        formatCepMask(e.target.value, (result: any) =>
                          form.setFieldsValue({ postal_code: result }),
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name="street" label="Logradouro">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name="district" label="Bairro">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="number" label="Número">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="city_id" label="Cidade">
                    <FormSelect
                      onSearch={(value: string) => searchCity(value)}
                      defaultValue={form.getFieldValue('city')?.description}
                      prefixLabel="code"
                      field={form.getFieldValue('city')}
                      items={cityList}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="email" label="E-mail">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="active" label="Ativo" rules={[{ required: true }]}>
                    <Select placeholder="Ativo" allowClear>
                      <Option key={1} value={1}>
                        Sim
                      </Option>
                      <Option key={0} value={0}>
                        Não
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="is_matriz" label="É matriz" rules={[{ required: true }]}>
                    <Select placeholder="Ativo" allowClear>
                      <Option key={1} value={1}>
                        Sim
                      </Option>
                      <Option key={0} value={0}>
                        Não
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="matriz" label="Empresa Matriz">
                    <Select
                      labelInValue={false}
                      placeholder="Digite para buscar..."
                      notFoundContent={
                        !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                      }
                      filterOption={false}
                      onSearch={(value) => searchCompanies(value)}
                      style={{ width: '100%' }}
                      showSearch
                      allowClear
                    >
                      {companyList.map((item: any) => (
                        <Option
                          key={item.id}
                          value={item?.company?.id}
                          style={{ textTransform: 'uppercase' }}>
                          {item?.company?.fantasy_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name="account_cashier" label="Conta Caixa">
                    <Select
                      labelInValue={false}
                      placeholder="Digite para buscar..."
                      notFoundContent={
                        !accountPlanList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                      }
                      filterOption={false}
                      onSearch={(value) => searchAccountPlan(value)}
                      style={{ width: '100%' }}
                      showSearch
                      allowClear>
                      {accountPlanList.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.code} - {item.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name="account_bank" label="Conta Banco">
                    <Select
                      labelInValue={false}
                      placeholder="Digite para buscar..."
                      notFoundContent={
                        !accountPlanList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                      }
                      filterOption={false}
                      onSearch={(value) => searchAccountPlan(value)}
                      style={{ width: '100%' }}
                      showSearch
                      allowClear>
                      {accountPlanList.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.code} - {item.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="nfse_last_number" label="Número última NFSe">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    multiple={false}
                    maxCount={1}
                    onPreview={handleOpenPreviewImage}
                    onChange={handleChange}
                    beforeUpload={handleUpload}
                    showUploadList={{ showRemoveIcon: false }}
                    style={{ width: '100%' }}>
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewImage.modalVisible}
                    title="Previzualização do documento"
                    footer={null}
                    onCancel={handleClosePreviewImage}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage.url} />
                  </Modal>
                </Col>
                <Col span={24}>
                  <Form.Item name="about" label="Sobre">
                    <TextArea rows={8} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="integration_fiscal_integranotas_token" label="Token Integra Notas">
                    <TextArea rows={3} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={4}>
                  <Button
                    onClick={() => setShowDrawer(false)}
                    style={{ marginRight: 8 }}
                    block
                    htmlType="button">
                    Cancelar
                  </Button>
                </Col>
                <Col span={4}>
                  <Button type="primary" htmlType="submit" block loading={loading}>
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
            <ul style={{ listStyle: 'none' }}>
              {errors?.length > 0 &&
                errors.map((item: any) => (
                  <li>
                    <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
                  </li>
                ))}
            </ul>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Serviços" key="services">
            <Form
              layout="vertical"
              autoComplete="off"
              onFinish={saveCompanyService}
              form={formCompanyService}
            >
              <Row gutter={15}>
                <Col span={20}>
                  <Form.Item name="service_id" label="Serviço" rules={[{ required: true }]}>
                    <FormSelect
                      onSearch={(value: string) => searchServices(value)}
                      defaultValue={form.getFieldValue('service')?.description}
                      field={form.getFieldValue('service')}
                      items={serviceList}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} style={{ marginTop: 30 }}>
                  <Button type="primary" htmlType="submit" style={{ width: '100%' }}>Incluir</Button>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={24}>
                  <Table
                    columns={[
                      {
                        title: 'Serviço',
                        key: 'service',
                        dataIndex: 'service',
                        sorter: (a: any, b: any) => a.service?.length - b.service?.length,
                        render: (value: number, record: any) => record?.service?.description
                      },
                      {
                        title: 'Ações',
                        key: 'actions',
                        align: 'center',
                        render: (text: any, record: any) => (
                          <Space size="small">
                            <Divider type="vertical" />
                            <Popconfirm
                              title="Deseja realmente excluir esse item?"
                              onConfirm={() => removeCompanyService(record.id)}
                              okText="Sim"
                              cancelText="Não">
                              <Button type="link" style={{ color: 'red' }}>
                                <DeleteOutlined /> Excluir
                              </Button>
                            </Popconfirm>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={companyServiceList}
                    rowKey={() => Math.random()}
                    loading={loading}
                    pagination={false}
                    tableLayout="auto"
                  />
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Cnaes" key="cnaes">
            <Form
              layout="vertical"
              autoComplete="off"
              onFinish={saveCompanyCnae}
              form={formCompanyCnae}
            >
              <Row gutter={15}>
                <Col span={20}>
                  <Form.Item name="cnae_id" label="Cnae" rules={[{ required: true }]}>
                    <FormSelect
                      onSearch={(value: string) => searchCnaes(value)}
                      defaultValue={form.getFieldValue('cnae')?.description}
                      field={form.getFieldValue('cnae')}
                      items={cnaeList}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} style={{ marginTop: 30 }}>
                  <Button type="primary" htmlType="submit" style={{ width: '100%' }}>Incluir</Button>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={24}>
                  <Table
                    columns={[
                      {
                        title: 'Cnae',
                        key: 'cnae',
                        dataIndex: 'cnae',
                        sorter: (a: any, b: any) => a.cnae?.length - b.cnae?.length,
                        render: (value: number, record: any) => record?.cnae?.description
                      },
                      {
                        title: 'Ações',
                        key: 'actions',
                        align: 'center',
                        render: (text: any, record: any) => (
                          <Space size="small">
                            <Divider type="vertical" />
                            <Popconfirm
                              title="Deseja realmente excluir esse item?"
                              onConfirm={() => removeCompanyCnae(record.id)}
                              okText="Sim"
                              cancelText="Não">
                              <Button type="link" style={{ color: 'red' }}>
                                <DeleteOutlined /> Excluir
                              </Button>
                            </Popconfirm>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={companyCnaeList}
                    rowKey={() => Math.random()}
                    loading={loading}
                    pagination={false}
                    tableLayout="auto"
                  />
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Impostos" key="taxes">
            <Form
              layout="vertical"
              autoComplete="off"
              onFinish={saveCompanyTax}
              form={formCompanyTax}
            >
              <Row gutter={15}>
                <Col span={16}>
                  <Form.Item name="tax_id" label="tax" rules={[{ required: true }]}>
                    <FormSelect
                      onSearch={(value: string) => searchTaxes(value)}
                      defaultValue={form.getFieldValue('tax')?.description}
                      field={form.getFieldValue('tax')}
                      items={taxList}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="aliquot" label="Alíquota" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4} style={{ marginTop: 30 }}>
                  <Button type="primary" htmlType="submit" style={{ width: '100%' }}>Incluir</Button>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={24}>
                  <Table
                    columns={[
                      {
                        title: 'Imposto',
                        key: 'tax',
                        dataIndex: 'tax',
                        sorter: (a: any, b: any) => a.tax?.length - b.tax?.length,
                        render: (value: number, record: any) => record?.tax?.description
                      },
                      {
                        title: 'Alíquota',
                        key: 'aliquot',
                        dataIndex: 'aliquot',
                        sorter: (a: any, b: any) => a.aliquot?.length - b.aliquot?.length,
                        render: (value: number, record: any) => `${value} %`
                      },
                      {
                        title: 'Ações',
                        key: 'actions',
                        align: 'center',
                        render: (text: any, record: any) => (
                          <Space size="small">
                            <Divider type="vertical" />
                            <Popconfirm
                              title="Deseja realmente excluir esse item?"
                              onConfirm={() => removeCompanyTax(record.id)}
                              okText="Sim"
                              cancelText="Não">
                              <Button type="link" style={{ color: 'red' }}>
                                <DeleteOutlined /> Excluir
                              </Button>
                            </Popconfirm>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={companyTaxList}
                    rowKey={() => Math.random()}
                    loading={loading}
                    pagination={false}
                    tableLayout="auto"
                  />
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
      <Modal
        title="Visualização do documento"
        visible={modalPhotoVisible}
        onOk={() => setModalPhotoVisible(false)}
        onCancel={() => setModalPhotoVisible(false)}>
        <img src={modalPhotoUrl} alt="Imagem do documento" width="100%" />
      </Modal>
    </LayoutFull>
  );
}
