import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Spin, message } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import numberFormatCurrency from '../../utils/numberFormatCurrency';

export function ReportCashMoviments() {
    const { Option } = Select;

    const { RangePicker } = DatePicker;
    const [data, setData] = useState<any>({});
    const [totals, setTotals] = useState<any>({});
    const [period, setPeriod] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [companyList, setCompanyList] = useState<any[]>([]);
    const [pdfUrl, setPdfUrl] = useState<string>('')
    const reportTemplateRef = useRef(null);

    const handlerFilter = async (values: any) => {
        try {
            setLoading(true);
            let periodFilder;
            if (values.date) {
                const [date_start, date_end] = values.date;
                setPeriod(values.date)
                periodFilder = [
                    date_start && moment(date_start).format('YYYY-MM-DD'),
                    date_end && moment(date_end).format('YYYY-MM-DD'),
                ];

                const { data } = await api.get('report/cash-moviments/pdf', {
                    params: {
                        date: periodFilder || undefined,
                        company_id: values?.company_id || undefined,
                    },
                    responseType: 'blob'
                });
                const file = new Blob([data], { type: 'application/pdf' });
                const url = URL.createObjectURL(file);
                setPdfUrl(url);

                // let totalsInput = 0
                // let totalOutput = 0

                // for (const row of data?.data) {
                //     if (row.input > 0) {
                //         totalsInput += Number(row.input);
                //     }

                //     if (row.output > 0) {
                //         totalOutput += Number(row.output);
                //     }
                // }

                // setTotals({ input: totalsInput, output: totalOutput });
                // setData(data);
            }
        } catch (err: any) {
            const errorMessages = err.response.data.errors || [err.message]
            for (const error of errorMessages) {
                message.error(error);
            }
        } finally {
            setLoading(false);
        }
    };

    const searchCompanies = async (search: string) => {
        try {
            setLoading(true);
            const { data } = await api.get('organization', {
                params: { name: search },
            });
            setCompanyList(data);
        } catch (err: any) {
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = async () => {
        window.document.title = 'ativos-digital.moviment-caixa'
        window.print()
    };

    const DocumentView = () => {
        if (loading) {
            return (
                <Row style={{ padding: 100 }}>
                    <Col span={24} className="text-center">
                        <Spin size="default" />
                    </Col>
                </Row>
            )
        }

        if (!loading && pdfUrl) {
            return (
                <div ref={reportTemplateRef} className="page-print report-balancet">
                    <div className="page-container">
                        <div className="page-content">
                            <iframe src={pdfUrl} width="100%" height="900px"></iframe>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <p>Não há dados para mostrar</p>
        )
    };

    return (
        <LayoutFull headerTitle="Relatório movimento caixa">
            <Row>
                <Col span={24}>
                    <Form autoComplete="off" layout="vertical" onFinish={handlerFilter}>
                        <Row gutter={15}>
                            <Col span={4}>
                                <Form.Item name="date" label="Data" rules={[{ required: true }]}>
                                    <RangePicker
                                        format="DD/MM/YYYY"
                                        placeholder={['Data Inicial', 'Data Final']}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                                    <Select
                                        labelInValue={false}
                                        placeholder="Digite para buscar..."
                                        notFoundContent={
                                            !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                                        }
                                        filterOption={false}
                                        onSearch={(value) => searchCompanies(value)}
                                        style={{ width: '100%' }}
                                        showSearch
                                        allowClear>
                                        {companyList.map((item: any) => (
                                            <Option key={item?.id} value={item?.company?.id} style={{ textTransform: 'uppercase' }}>
                                                {item?.company?.fantasy_name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<SearchOutlined />}
                                    style={{ marginTop: 30, marginRight: 20 }}>
                                    Buscar
                                </Button>
                            </Col>
                            <Col span={2}>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    icon={<PrinterOutlined />}
                                    style={{ marginTop: 30, marginRight: 20 }}
                                    onClick={() => handlePrint()}
                                >
                                    Imprimir
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <DocumentView />
                </Col>
            </Row>
        </LayoutFull>
    );
}
