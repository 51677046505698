import { Transfer, message } from 'antd';
import { useParams } from 'react-router-dom';
import type { TransferDirection } from 'antd/es/transfer';
import { useCallback, useEffect, useState } from 'react';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import './styles.css';

export function ReferencialPlan() {
  const { segmentId } = useParams<any>();

  const [targetKeys, setTargetKeys] = useState<any[]>(['1']);
  const [sourceKeys, setSourceKeys] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const getData = useCallback(async () => {
    try {
      const [response1, response2] = await Promise.all([
        await api.get('account-plan-default', {
          params: { page: 1, per_page: 10000 },
        }),
        await api.get('referencial-plan', {
          params: {
            page: 1,
            per_page: 10000,
            group_account_plan_id: segmentId,
          },
        }),
      ]);

      const accountPlanDefaultMapped = response1.data.map((item: any) => ({
        key: `${item.id}`,
        title: item.description,
      }));

      setSourceKeys(accountPlanDefaultMapped);

      const accountPlanDefaultSegmentMapped = response2.data.map(
        (item: any) => `${item.account_plan_default_id}`,
      );
      setTargetKeys(accountPlanDefaultSegmentMapped);
    } catch (err: any) {
      message.error(err.message);
    }
  }, [segmentId]);

  const onChange = async (
    nextTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[],
  ) => {
    try {
      if (direction === 'right') {
        await api.post('referencial-plan/create-keys', {
          segment_id: segmentId,
          keys: moveKeys,
        });
      } else if (direction === 'left') {
        await api.post('referencial-plan/destroy-keys', {
          segment_id: segmentId,
          keys: moveKeys,
        });
      }
    } catch (err: any) {
      message.error(err.message);
      setTargetKeys(nextTargetKeys);
    } finally {
      setTargetKeys(nextTargetKeys);
    }
  };

  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Plano de contas por segmento">
      <div className="container">
        <Transfer
          dataSource={sourceKeys}
          titles={['Plano de contas', 'Itens incluídos']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onChange}
          onSelectChange={onSelectChange}
          render={(item) => item?.title}
          operations={['Incluir itens', 'Remover itens']}
          showSearch
          locale={{
            itemUnit: 'Item',
            itemsUnit: 'Itens',
            searchPlaceholder: 'Digite em maiúsculo para pesquisar',
            notFoundContent: 'Nenhum item para mostrar',
          }}
          onSearch={(direction: any, value: string) => value.toUpperCase()}
        />
      </div>
    </LayoutFull>
  );
}
