import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Spin, message } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import numberFormatCurrency from '../../utils/numberFormatCurrency';

export function ReportRevenueDefault() {
  const { Option } = Select;

  const { RangePicker } = DatePicker;
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const reportTemplateRef = useRef(null);

  const handlerFilter = async (values: any) => {
    try {
      setLoading(true);
      let periodFilder;
      if (values.date) {
        const [date_start, date_end] = values.date;
        periodFilder = [
          date_start && moment(date_start).format('YYYY-MM-DD'),
          date_end && moment(date_end).format('YYYY-MM-DD'),
        ];

        const { data } = await api.get('report/revenue', {
          params: {
            date: periodFilder || undefined,
            company_id: values?.company_id || undefined,
          },
        });

        const months = getMonthsBetween(date_start, date_end);

        for (const month of months) {
          const has = data.data?.find((item: any) => item.date === month)
          if (!has) {
            data.data.push({
              date: month,
              a_vista: 0,
              a_prazo: 0
            })
          }
        }

        data.data?.sort((a: any, b: any) => {
          const dateA = new Date(`01/${a.date}`);
          const dateB = new Date(`01/${b.date}`);
          if (dateA.getTime() < dateB.getTime()) {
            return -1;
          }
          if (dateA.getTime() > dateB.getTime()) {
            return 1;
          }
          return 0;
        });

        setData(data);
      }
    } catch (err: any) {
      const errorMessages = err.response.data.errors || [err.message]
      for (const error of errorMessages) {
        message.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const searchCompanies = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getMonthsBetween = (startDate: string, endDate: string) => {
    const months = [];

    const currentDate = moment(startDate).clone().startOf('month');

    while (currentDate.isSameOrBefore(endDate)) {
      months.push(currentDate.format('MM/YYYY'));
      currentDate.add(1, 'month');
    }

    return months;
  }

  const handlePrint = async () => {
    window.document.title = 'ativos-digital.relatorio-faturamento'
    window.print()
  };

  const DocumentView = () => {
    if (loading) {
      return (
        <Row style={{ padding: 100 }}>
          <Col span={24} className="text-center">
            <Spin size="default" />
          </Col>
        </Row>
      )
    }

    if (!loading && data?.data) {
      return (
        <div
          ref={reportTemplateRef}
          className="page-print reports">
          <div className="page-container">
            <div className="page-header">
              <div className='avatar'>
                <img src={process.env.REACT_APP_AVATAR_URL + data?.company?.avatar} height={90} />
              </div>
              <div className='header'>
                <span><strong>Razão Social</strong> {data?.company?.social_name}</span>
                <span><strong>CNPJ/CPF:</strong>  {data?.company?.document}</span>
                <span><strong>E-mail:</strong>  {data?.company?.email}</span>
                <span><strong>Período:</strong>  {data?.period?.start} até {data?.period?.end}</span>
              </div>
            </div>
            <div className="page-content">
              <h1>RELATÓRIO DE FATURAMENTO</h1>
              <table className="" style={{ width: '100%' }}>
                <thead>
                  <th style={{ textAlign: 'left' }}>MÊS/ANO</th>
                  <th style={{ textAlign: 'right' }}>À VISTA</th>
                  <th style={{ textAlign: 'right' }}>À PRAZO</th>
                  <th style={{ textAlign: 'right' }}>SUBTOTAL</th>
                </thead>
                <tbody>
                  {(data?.data || []).map((item: any) => (
                    <tr>
                      <td style={{ fontWeight: 'bold' }}>{item.date}</td>
                      <td style={{ textAlign: 'right' }}>{numberFormatCurrency(item.a_vista)}</td>
                      <td style={{ textAlign: 'right' }}>{numberFormatCurrency(item.a_prazo)}</td>
                      <td style={{ textAlign: 'right' }}>{numberFormatCurrency(item.subtotal)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className="py-2">
                Faturamento total no período <strong>{numberFormatCurrency(data?.totalGeral)}</strong>{' '}
                do(s) último(s) <strong>{data?.data?.length} mes(es)</strong>.
              </p>
              <p className="py-2">
                Declaramos para os devidos fins, que a Relação de Faturameto é
                autêntica e terá finalidade especifica de fornecer informações de
                movimentação econômica mensal para compor registros de dados
                cadastrais e fiscais da pessoa jurídica.
              </p>
            </div>
            {data?.data.length &&
              (
                <div className='signers'>
                  <div className='signer'>
                    <img src={process.env.REACT_APP_SIGNATURES_URL + data.accountant.assignature} />
                    <strong>{data.accountant.social_name}</strong>
                    <p>CPF/CNPJ: {data.accountant.document} - CRC: {data.accountant.crc}</p>
                  </div>
                  <div className='signer'>
                    <img src={process.env.REACT_APP_SIGNATURES_URL + data.partner.assignature} />
                    <strong>{data.partner.social_name}</strong>
                    <p>CPF/CNPJ: {data.partner.document} - Sócio Responsável</p>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      )
    }

    return (
      <p>Não há dados para mostrar</p>
    )
  }

  return (
    <LayoutFull headerTitle="Relatório de faturamento">
      <Row>
        <Col span={24}>
          <Form autoComplete="off" layout="vertical" onFinish={handlerFilter}>
            <Row gutter={15}>
              <Col span={4}>
                <Form.Item name="date" label="Data" rules={[{ required: true }]}>
                  <RangePicker
                    format="DD/MM/YYYY"
                    placeholder={['Data Inicial', 'Data Final']}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                  <Select
                    labelInValue={false}
                    placeholder="Digite para buscar..."
                    notFoundContent={
                      !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                    }
                    filterOption={false}
                    onSearch={(value) => searchCompanies(value)}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear>
                    {companyList.map((item: any) => (
                      <Option key={item?.id} value={item?.company?.id} style={{ textTransform: 'uppercase' }}>
                        {item?.company?.fantasy_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  style={{ marginTop: 30, marginRight: 20 }}>
                  Buscar
                </Button>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="button"
                  icon={<PrinterOutlined />}
                  style={{ marginTop: 30, marginRight: 20 }}
                  onClick={() => handlePrint()}
                >
                  Imprimir
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <DocumentView />
        </Col>
      </Row>
    </LayoutFull>
  );
}
