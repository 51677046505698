import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  message,
  notification,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import { EventTypeOptions } from '../../models/EventType';
import api from '../../services/api.service';
import FormSelect from '../../components/FormSelect';
import { useAuth } from '../../contexts/auth.context';

type FormFilter = {
  created_at: string[] | undefined;
  conciliated: number;
  description: string;
  historic_complement: string;
  historic_id: number;
};

export function EventDefault() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [accountPlanList, setAccountPlanList] = useState<any[]>([]);
  const [eventType, setEventType] = useState<any>(3);
  const [eventDefaultAccountList, setEventDefaultAccountList] = useState<any>([]);
  const [historicList, setHistoricList] = useState<any>([]);
  const [paymentTypeList, setPaymentTypeList] = useState<any>([]);
  const [documentTypeList, setDocumentTypeList] = useState<any>([]);
  const [peopleList, setPeopleList] = useState<any>([]);
  const { user } = useAuth();

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const [formAccounts] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const columns: any[] = [
    {
      title: 'Código',
      key: 'code',
      dataIndex: 'code',
      sorter: (a: any, b: any) => a.code.length - b.code.length,
    },
    {
      title: 'Descrição',
      key: 'description',
      dataIndex: 'description',
      sorter: (a: any, b: any) => a.description.length - b.description.length,
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
      sorter: (a: any, b: any) => a.type.length - b.type.length,
      render: (value: string, record: any) => (
        <Tag color="blue">{EventTypeOptions[value]?.label}</Tag>
      ),
    },
    {
      title: 'Histórico complementar',
      key: 'historic_complement',
      dataIndex: 'historic_complement',
      sorter: (a: any, b: any) => a.historic_complement.length - b.historic_complement.length,
    },
    {
      title: 'Cadastrado em',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: (a: any, b: any) => a.created_at.length - b.created_at.length,
      render: (value: string, record: any) => value && moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (value: number, record: any) => (
        <Tag color={value ? 'green' : 'red'}>{value ? 'Sim' : 'Não'}</Tag>
      ),
      sorter: (a: any, b: any) => a?.conciliate - b?.conciliate,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record.id)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
      const { data } = await api.get('event-default', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.date) {
      const [date_start, date_end] = values.date;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      conciliated: values?.conciliated || undefined,
      description: values?.description?.trim() || undefined,
      historic_complement: values?.historic_complement?.trim() || undefined,
      historic_id: values?.historic_id || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    async (id: number) => {
      setEventDefaultAccountList([]);
      form.resetFields();
      formAccounts.resetFields();
      searchPaymentType('');
      searchDocumentType('');
      searchHistoric('');
      searchAccountPlan('');

      const { data } = await api.get(`event-default/${id}`);

      data.date = moment(data.date);
      form.setFieldsValue(data);

      data?.event_default_accounts?.forEach((item: any) => {
        if (item.type === 'debit') {
          if (![2, 3, 4].includes(item.type)) {
            setEventDefaultAccountList((old: any) => [item, ...old]);
          }
        } else if (![1, 3, 2].includes(item.type)) {
          setEventDefaultAccountList((old: any) => [item, ...old]);
        }
      });
      setEventType(data.type);
      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`event-default/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    formAccounts.resetFields();
    setEventDefaultAccountList([]);
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    const eventDefaultAccounts = [];

    if (eventDefaultAccountList.length) {
      for (const account of eventDefaultAccountList) {
        const item: any = {};

        if (account.id) {
          item.id = account.id;
          item.event_id = account.event_id;
        }

        item.type = account.type;
        item.account_plan_id = account.account_plan_id;
        item.historic_id = account.historic_id;

        eventDefaultAccounts.push(item);
      }
    }

    const data = { ...values, event_default_accounts: eventDefaultAccounts };

    setLoading(true);
    if (data.id) {
      try {
        await api.put(`event-default/${data.id}`, data);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        delete data.id;
        data.company_id = user?.company_default_id
        await api.post('event-default', data);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchAccountPlan = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('account-plan', {
        params: { page: 1, per_page: 50, description: search, company_id: user?.company_default_id },
      });
      setAccountPlanList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchHistoric = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('historic', {
        params: { page: 1, per_page: 50, description: search, company_id: user?.company_default_id },
      });
      setHistoricList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchPaymentType = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('payment-type', {
        params: { page: 1, per_page: 100, description: search },
      });
      setPaymentTypeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchDocumentType = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('document-type', {
        params: { page: 1, per_page: 100, description: search },
      });
      setDocumentTypeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchPeople = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('people', {
        params: { search_field: 'social_name', search_text: search, company_id: user?.company_default_id },
      });
      setPeopleList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerAddEventAccount = (values: any) => {
    setEventDefaultAccountList((old: any) => [...old, values]);
  };

  const handlerDeleteEventAccountList = (item: any) => {
    const data = eventDefaultAccountList.filter((element: any) => element !== item);
    setEventDefaultAccountList(data);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Lançamentos Padrões">
      <Form onFinish={handlerFilter} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item name="description" label="Descrição">
              <Input placeholder="Descrição" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="historic_complement" label="Histórico complementar">
              <Input placeholder="Histórico complementar" allowClear />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="conciliated" label="Conciliado">
              <Select placeholder="Conciliado" allowClear>
                <Option value={1}>Sim</Option>
                <Option value={0}>Não</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="date" label="Data">
              <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Data Inicial', 'Data Final']}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width="60%"
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            type: 3,
            active: 1,
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={19}>
              <Form.Item name="description" label="Descrição">
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="active" label="Ativo" rules={[{ required: true }]}>
                <Select placeholder="Ativo" allowClear>
                  <Option value={1}>Sim</Option>
                  <Option value={0}>Não</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="type" label="Tipo de lançamento" rules={[{ required: true }]}>
                <Select placeholder="Tipo de lançamento" onChange={(value) => setEventType(value)}>
                  <Option value={1}>Um débito para vários créditos</Option>
                  <Option value={2}>Um crédito para vários débitos</Option>
                  <Option value={3}>Um débito para um crédito</Option>
                  <Option value={4}>Vários débitos para vários créditos</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="document_type_id" label="Tipo de documento">
                <FormSelect
                  onSearch={(value: string) => searchDocumentType(value)}
                  defaultValue={form.getFieldValue('document_type')?.description}
                  items={documentTypeList}
                  field={form.getFieldValue('document_type')}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="payment_type_id" label="Tipo de pagamento">
                <FormSelect
                  onSearch={(value: string) => searchPaymentType(value)}
                  defaultValue={form.getFieldValue('payment_type')?.description}
                  items={paymentTypeList}
                  field={form.getFieldValue('payment_type')}
                  prefixLabel="code"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="people_id" label="Pessoa">
                <FormSelect
                  onSearch={(value: string) => searchPeople(value)}
                  defaultValue={form.getFieldValue('people')?.description}
                  items={peopleList}
                  field={form.getFieldValue('people')}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="historic_id" label="Histórico padrão">
                <FormSelect
                  onSearch={(value: string) => searchHistoric(value)}
                  defaultValue={form.getFieldValue('historic')?.description}
                  items={historicList}
                  field={form.getFieldValue('historic')}
                  prefixLabel="code"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="lock_edit" label="Bloquear edição" rules={[{ required: true }]}>
                <Select placeholder="Bloquear edição">
                  <Option value={1}>Sim</Option>
                  <Option value={0}>Não</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="historic_complement" label="Histórico Complementar">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form form={formAccounts} onFinish={handlerAddEventAccount} autoComplete="off" layout="vertical">
          <Row>
            <Col span={24} style={{ backgroundColor: '#eee', padding: '5px' }}>
              <table style={{ textAlign: 'left', width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ padding: 5, width: '35%' }}>
                      <Form.Item name="account_plan_id" label="Conta">
                        <Select
                          labelInValue={false}
                          placeholder="Digite para buscar..."
                          notFoundContent={
                            !accountPlanList ? (
                              <Spin size="small" />
                            ) : (
                              <div>Nenhum dado encontrado</div>
                            )
                          }
                          filterOption={false}
                          onSearch={(value) => searchAccountPlan(value)}
                          style={{ width: '100%' }}
                          showSearch
                          allowClear>
                          {accountPlanList.map((item: any) => (
                            <Option key={item.id} value={item.id}>
                              {item.code} - {item.description}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ padding: 5, width: '10%' }}>
                      <Form.Item name="type" label="Tipo">
                        <Select placeholder="" style={{ width: '100%' }}>
                          <Option value="debit" disabled={eventType === 1}>
                            Débito
                          </Option>
                          <Option value="credit" disabled={eventType === 2}>
                            Crédito
                          </Option>
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ padding: 5, width: '5%', marginTop: 8 }}>
                      <Button type="primary" htmlType="submit">
                        <PlusOutlined />
                      </Button>
                    </td>
                  </tr>
                  {(eventDefaultAccountList || []).map((item: any) => (
                    <tr key={item.id}>
                      <td style={{ padding: 5, width: '35%' }}>
                        {item?.account_plan?.code} - {item?.account_plan?.description}
                      </td>
                      <td style={{ padding: 5, width: '10%' }}>
                        <Tag color={item.type === 'credit' ? 'green' : 'red'}>
                          {item.type === 'credit' ? 'CRÉDITO' : 'DÉBITO'}
                        </Tag>
                      </td>
                      <td style={{ padding: 5, width: '5%' }}>
                        <Button
                          type="default"
                          htmlType="button"
                          style={{ background: 'red' }}
                          onClick={() => handlerDeleteEventAccountList(item)}>
                          <DeleteOutlined style={{ color: '#fff' }} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Form>
        <Row gutter={15} style={{ marginTop: 15 }}>
          <Col span={4}>
            <Button
              onClick={() => setShowDrawer(false)}
              style={{ marginRight: 8 }}
              block
              htmlType="button">
              Cancelar
            </Button>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              htmlType="button"
              block
              loading={loading}
              onClick={() => form.submit()}>
              Salvar
            </Button>
          </Col>
        </Row>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
