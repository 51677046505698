import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  message,
  notification,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import FormSelect from '../../components/FormSelect';
import { useAuth } from '../../contexts/auth.context';

type FormFilter = {
  created_at: string[] | undefined;
  code: number;
  description: string;
  account: string;
  type: number;
  active: boolean;
};

export function AccountPlanDefault() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [parentAccountPlanList, setParentAccountPlanList] = useState<any[]>([]);
  const { user } = useAuth();

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Código',
      key: 'code',
      dataIndex: 'code',
      sorter: (a: any, b: any) => a.code.length - b.code.length,
    },
    {
      title: 'Conta',
      key: 'account',
      dataIndex: 'account',
      sorter: (a: any, b: any) => a.account.length - b.account.length,
    },
    {
      title: 'Descrição',
      key: 'description',
      dataIndex: 'description',
      sorter: (a: any, b: any) => a.description.length - b.description.length,
    },
    {
      title: 'Conta Pai',
      key: 'parent',
      dataIndex: 'parent',
      sorter: (a: any, b: any) => a.parent.length - b.parent.length,
      render: (value: string, record: any) => record?.parent?.description,
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
      sorter: (a: any, b: any) => a.type.length - b.type.length,
      render: (value: boolean, record: any) => (
        <div>
          {record.type === 'a' && <Tag color="blue">ANALÍTICA</Tag>}
          {record.type === 's' && <Tag color="green">SINTÉTICA</Tag>}
        </div>
      ),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (value: boolean, record: any) => (
        <Switch
          size="small"
          defaultChecked={Boolean(value)}
          onClick={(value) => handleToggleActive(record.id, +value, record)}
        />
      ),
      sorter: (a: any, b: any) => a?.active - b?.active,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage } };
      const { data } = await api.get('account-plan-default', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [date_start, date_end] = values.created_at;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      code: values.code || undefined,
      description: values.description || undefined,
      account: values.account || undefined,
      type: values.type || undefined,
      active: values?.active,
    });
  };

  const hidrateFormWithValues = useCallback(
    (values: any) => {
      form.setFieldsValue(values);
      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const handleToggleActive = async (id: string, active: number, record: any) => {
    try {
      record.active = active;
      await api.put(`account-plan-default/${id}`, record);
      message.success('Registro atualizado com sucesso');
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`account-plan-default/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    setLoading(true);
    if (values.id) {
      try {
        await api.put(`account-plan-default/${values.id}`, values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      delete values.id;
      try {
        await api.post('account-plan-default', values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchParentAccountPlan = async (search: string) => {
    try {
      let params = {};

      if (isNaN(parseInt(search))) {
        params = { description: search };
      } else {
        params = { code: search };
      }

      setLoading(true);
      const { data } = await api.get('account-plan-default', {
        params: { per_page: 100, company_id: user?.company_default_id, ...params },
      });
      setParentAccountPlanList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Plano de Contas Padrão">
      <Form onFinish={handlerFilter} onFinishFailed={() => { }} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={4}>
            <Form.Item name="code" label="Código">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="description" label="Descrição">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="account" label="Conta">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="type" label="Tipo">
              <Select placeholder="Tipo" allowClear>
                <Option value="a">Analítico</Option>
                <Option value="s">Sintético</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="active" label="Ativo">
              <Select placeholder="Ativo" allowClear>
                <Option value="1">Ativos</Option>
                <Option value="0">Inativos</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={8}>
              <Form.Item name="code" label="Código" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="account" label="Conta" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="sequence" label="Sequencia">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" label="Descrição" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="type" label="Tipo" rules={[{ required: true }]}>
                <Select placeholder="Tipo">
                  <Option value="a">Analítico</Option>
                  <Option value="s">Sintético</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="parent_id" label="Conta pai">
                <FormSelect
                  onSearch={(value: string) => searchParentAccountPlan(value)}
                  defaultValue={form.getFieldValue('parent')?.description}
                  items={parentAccountPlanList}
                  field={form.getFieldValue('parent')}
                  prefixLabel="code"
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="demo_group_dre" label="Grupo DRE">
                <Select
                  placeholder="Grupo DRE"
                  style={{ width: '100%' }}
                  allowClear
                  options={[
                    {
                      label: 'Receita Líquida',
                      options: [
                        {
                          label: 'Custos Mercadorias Vendida',
                          value: 'custos_mercadorias_vendidas',
                        },
                      ],
                    },
                    {
                      label: 'Lucro Bruto',
                      options: [
                        {
                          label: 'Despesas Administrativas',
                          value: 'despesas_administrativas',
                        },
                        {
                          label: 'Despesas com Pessoal',
                          value: 'despesas_com_pessoal',
                        },
                        {
                          label: 'Despesas Financeiras',
                          value: 'despesas_financeiras',
                        },
                        {
                          label: 'Receitas Financeiras',
                          value: 'receitas_financeiras',
                        },
                        {
                          label: 'Outras Despesas Operacionais',
                          value: 'outras_despesas_operacionais',
                        },
                        {
                          label: 'Outras Receitas Operacionais',
                          value: 'outras_receitas_operacionais',
                        },
                      ],
                    },
                    {
                      label: 'Resultado Operacional Liquido',
                      options: [
                        {
                          label: 'Despesas Não Operacionais',
                          value: 'despesas_nao_operacionais',
                        },
                        {
                          label: 'Receitas Não Operacionais',
                          value: 'receitas_nao_operacionais',
                        },
                      ],
                    },
                    {
                      label: 'Resultados Antes do IR',
                      options: [{ label: 'Provisões', value: 'provisoes' }],
                    },
                    {
                      label: 'Outros',
                      options: [
                        { label: 'Não Faz Parte', value: 'none' },
                        {
                          label: 'Receita Operacional',
                          value: 'receita_operacional',
                        },
                        { label: 'Deduções', value: 'deducoes' },
                      ],
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="payment" label="Pagamento">
                <Select placeholder="Pagamento" style={{ width: '100%' }} allowClear>
                  <Option key="a_vista" value="a_vista">
                    À vista
                  </Option>
                  <Option key="a_prazo" value="a_prazo">
                    À prazo
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="active" label="Ativo" rules={[{ required: true }]}>
                <Select placeholder="Ativo" style={{ width: '100%' }} allowClear>
                  <Option key="1" value={1}>
                    Sim
                  </Option>
                  <Option key="2" value={0}>
                    Não
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
