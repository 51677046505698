import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';

import { NavLink } from 'react-router-dom';
import { useMenuList } from '../../contexts/menu.context';

function MenuSidebar() {
  const { menuList } = useMenuList();

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={['painel']}>
      {menuList &&
        menuList.map((module) => (
          <SubMenu key={`SubMenu-${module.id}`} title={module.description}>
            {(module.submodules || []).map(
              (submodule) =>
                submodule.type_permissions.includes('menu') && (
                  <Menu.Item key={`MenuItem-${submodule.id}`}>
                    <NavLink to={`/${submodule.route}`}>{submodule.description}</NavLink>
                  </Menu.Item>
                ),
            )}
          </SubMenu>
        ))}
    </Menu>
  );
}

export default MenuSidebar;
