import ptBr from 'antd/es/locale/pt_BR';
import { ConfigProvider } from 'antd';
import { AuthProvider } from './contexts/auth.context';
import { Routes } from './routes';
import { MenuProvider } from './contexts/menu.context';

function App() {
  return (
    <ConfigProvider locale={ptBr}>
      <AuthProvider>
        <MenuProvider>
          <Routes />
        </MenuProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
