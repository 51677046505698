import { Layout } from 'antd';

export default function Footer() {
  const { Footer } = Layout;

  return (
    <Footer style={{ textAlign: 'center' }}>
      <p>
        Todos os direitos reservados. Ativos Digital 2021 &copy;. Powered by Interativa Software
      </p>
    </Footer>
  );
}
