import { Alert, Button, Layout, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import FeatureImage from '../../assets/images/feature-image.png';
import './styles.css';
import api from '../../services/api.service';
import InsideError from '../../components/InsideError';

interface ConfirmEmailParams {
  hash: string;
}

export function ConfirmEmail() {
  const [errors, setErrors] = useState<string | string[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const { hash } = useParams<ConfirmEmailParams>();

  const hashIsValid = useCallback(async () => {
    try {
      setLoading(true);
      await api.get(`confirm-email/${hash}`);

      setSuccess('Email validado com sucesso!');
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setErrors(error?.response?.data?.errors || error.message);
        return;
      }
      setErrors('Ocorreu um erro durante a validação');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Layout>
      <div className="confirm-email-container">
        <div className="features">
          <img src={FeatureImage} alt="Ilustração" />
          <h1>Sua contabilidade digital</h1>
          <div className="sc-enrZtP ihJIQH">
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                1
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Tecnologia para garantir sua contabilidade 100% correta</span>
              </span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                2
              </span>
              <span className="sc-btlduw kMTXgk">Gestão de notas fiscais</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                3
              </span>
              <span className="sc-btlduw kMTXgk">Financeiro + Contabilidade</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                4
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Aplicativo financeiro e contábil</span>
              </span>
            </div>
          </div>
        </div>
        <div className="confirm-email-content">
          <img src={Logo} alt="Ilustração" />
          <div className="content-wrapper">
            <h1>
              Bem-vindo ao <span>Portal</span> da Ativos Digital
            </h1>
            <br />
            <br />

            <Button type="primary" onClick={() => hashIsValid()}>
              Clique aqui para confirmar seu email
            </Button>
            {loading && (
              <>
                <p>Estamos validando a confirmação de email ...</p>
                <Spin size="large" spinning={loading} />
              </>
            )}

            {Boolean(success) && (
              <Button
                type="primary"
                htmlType="submit"
                block
                className="confirm-email-button"
                style={{
                  backgroundColor: 'rgb(45, 205, 105)',
                  borderColor: 'rgb(45, 205, 105)',
                  height: 42,
                }}
                onClick={() => {
                  window.location.href = '/login';
                }}>
                <strong>Ir para login</strong>
              </Button>
            )}
            {Boolean(errors) && <InsideError errors={errors} />}
            {Boolean(success) && <Alert message={success} type="success" />}
          </div>
        </div>
      </div>
    </Layout>
  );
}
