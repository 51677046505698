import { UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Layout } from 'antd';
import { useState } from 'react';
import FeatureImage from '../../assets/images/feature-image.png';
import Logo from '../../assets/images/logo.png';
import InsideError from '../../components/InsideError';
import api from '../../services/api.service';
import './styles.css';

export default function ForgotPassword() {
  const [errors, setErrors] = useState<string | string[]>([]);
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handlerOnSubmit = async (data: any) => {
    try {
      await api.post('forgot-password', data);
      setLoading(true);
      setSuccess('Senha provisória enviada com sucesso');
    } catch (err: any) {
      setErrors(err?.response?.data?.errors || err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="forgot-container">
        <div className="features">
          <img src={FeatureImage} alt="Ilustração" />
          <h1>Sua contabilidade digital</h1>
          <div className="sc-enrZtP ihJIQH">
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                1
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Tecnologia para garantir sua contabilidade 100% correta</span>
              </span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                2
              </span>
              <span className="sc-btlduw kMTXgk">Gestão de notas fiscais</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                3
              </span>
              <span className="sc-btlduw kMTXgk">Financeiro + Contabilidade</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                4
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Aplicativo financeiro e contábil</span>
              </span>
            </div>
          </div>
        </div>
        <div className="forgot-form">
          <img src={Logo} alt="Ilustração" />
          <div className="form-wrapper">
            <Form
              layout="vertical"
              initialValues={{
                email: '',
              }}
              onFinish={handlerOnSubmit}>
              <h1>
                Bem-vindo ao <span>Portal</span> da Ativos Digital
              </h1>
              <p>Informe seu email cadastrado para receber a senha provisória</p>
              <Form.Item name="email" rules={[{ required: true }]} label="Seu e-mail">
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="E-mail"
                  type="email"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="forgot-form-button"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                    loading
                  }
                  style={{
                    backgroundColor: 'rgb(45, 205, 105)',
                    borderColor: 'rgb(45, 205, 105)',
                    height: 42,
                  }}>
                  <strong>Enviar</strong>
                </Button>
              </Form.Item>
              {Boolean(errors) && <InsideError errors={errors} />}
              {Boolean(success) && (
                <Alert message={success} type="success" style={{ marginTop: 10 }} />
              )}
              <a href="/login">Já tem uma conta? Faça login</a>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
