import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Spin, message, Input } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import numberFormatCurrency from '../../utils/numberFormatCurrency';
import { formatDocument } from '../../utils/masks';

export function ReportRevenueBB() {
  const { Option } = Select;

  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [data, setData] = useState<any>({});
  const reportTemplateRef = useRef(null);

  const handlerFilter = async (values: any) => {
    try {
      setLoading(true);
      let periodFilder;
      if (values.date) {
        const [date_start, date_end] = values.date;
        periodFilder = [
          date_start && moment(date_start).format('YYYY-MM-DD'),
          date_end && moment(date_end).format('YYYY-MM-DD'),
        ];

        const { data } = await api.get('/report/revenuebb', {
          params: {
            date: periodFilder || undefined,
            company_id: values?.company_id || undefined,
            credit_card: values?.credit_card || undefined,
            bank_check: values?.bank_check || undefined,
            ticket: values?.ticket || undefined,
            medium_term: values?.medium_term || undefined,
          },
        });

        const months = getMonthsBetween(date_start, date_end);

        for (const month of months) {
          const has = data.data?.find((item: any) => item.date === month)
          if (!has) {
            data.data.push({
              date: month,
              a_vista: 0,
              a_prazo: 0
            })
          }
        }

        data.data?.sort((a: any, b: any) => {
          const dateA = new Date(`01/${a.date}`);
          const dateB = new Date(`01/${b.date}`);
          if (dateA.getTime() < dateB.getTime()) {
            return -1;
          }
          if (dateA.getTime() > dateB.getTime()) {
            return 1;
          }
          return 0;
        });

        setData(data);
      }
    } catch (err: any) {
      const errorMessages = err.response.data.errors || [err.message]
      for (const error of errorMessages) {
        message.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const searchCompanies = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getMonthsBetween = (startDate: string, endDate: string) => {
    const months = [];

    const currentDate = moment(startDate).clone().startOf('month');

    while (currentDate.isSameOrBefore(endDate)) {
      months.push(currentDate.format('MM/YYYY'));
      currentDate.add(1, 'month');
    }

    return months;
  }

  const handlePrint = async () => {
    window.document.title = 'ativos-digital.relatorio-faturamento-bb'
    window.print()
  };

  const DocumentView = () => {
    if (loading) {
      return (
        <Row style={{ padding: 100 }}>
          <Col span={24} className="text-center">
            <Spin size="default" />
          </Col>
        </Row>
      )
    }

    if (!loading && data?.data) {
      return (
        <div
          ref={reportTemplateRef}
          className="page-print report-bb">
          <div className="page-container">
            <div className="page-content">
              <div className="parent-content my-3">
                <div className="logo-bb">
                  <img src={`${process.env.REACT_APP_AVATAR_URL}logo-banco-brasil.png`} />
                  <h1>Relação de Faturamento Pessoa Jurídica</h1>
                </div>
                <div className="table header">
                  <div className="cell social-name">
                    <span className="label">Razão Social</span>
                    <span className="value">{data?.company?.social_name}</span>
                  </div>
                  <div className="cell">
                    <span className="label">CNPJ</span>
                    <span className="value">{formatDocument(data?.company?.document)}</span>
                  </div>
                </div>
                <div className="text text-blue text-about">
                  <strong>Relação de faturamento - últimos 12 meses</strong>
                  <i style={{ fontSize: 13 }}>
                    Para empresas com menos de 12 meses de existência:
                    multiplique o faturamento médio mensal dos meses
                    que está em funcionamento por 1. Exemplo: empresa
                    funciona há 4 meses: some os faturamentos recebidos,
                    divide por 4 e multiplique por 12 e desconsidere os
                    meses não faturados.
                  </i>
                </div>
                <div className="table">
                  <div className="cell">
                    <span className="label">Mês/ano</span>
                  </div>
                  <div className="cell">
                    <span className="label">À vista - R$</span>
                  </div>
                  <div className="cell">
                    <span className="label">À prazo - R$</span>
                  </div>
                </div>
                {(data?.data || []).map((item: any) => (
                  <div className="table">
                    <div className="cell">
                      <span className="value">{item.date}</span>
                    </div>
                    <div className="cell">
                      <span className="value">{item.a_vista}</span>
                    </div>
                    <div className="cell">
                      <span className="value">{item.a_prazo}</span>
                    </div>
                  </div>
                ))}
                <div className="table">
                  <div className="cell">
                    <span className="value text-blue">Total</span>
                  </div>
                  <div className="cell">
                    <span className="value">{data?.totals?.a_vista}</span>
                  </div>
                  <div className="cell">
                    <span className="value">{data?.totals?.a_prazo}</span>
                  </div>
                </div>
                <div className="table" style={{ marginTop: 8 }}>
                  <div className="cell">
                    <span className="label">Faturamento bruto total - último(s) {data?.data?.lenthg} mes(es) - R$</span>
                    <span className="value">{data?.totals?.total}</span>
                  </div>
                </div>
                <span className="label text-blue">Percentual do total do faturamento à prazo que somandos representam 100%</span>
                <div className="table" style={{ marginBottom: 15 }}>
                  <div className="cell">
                    <span className="label">Cartão-%</span>
                    <span className="value">{data?.credit_card}</span>
                  </div>
                  <div className="cell" style={{ width: 100, borderColor: '#0038A8', borderWidth: 2 }}>
                    <span className="label">Cheque-%</span>
                    <span className="value">{data?.bank_check}</span>
                  </div>
                  <div className="cell" style={{ borderColor: '#0038A8', borderWidth: 2 }}>
                    <span className="label">Boleto/Título-%</span>
                    <span className="value">{data?.ticket}</span>
                  </div>
                  <div className="cell" style={{ marginLeft: 20, flex: 2 }}>
                    <span className="label">Prazo Médio de recebimento à prazo em dias</span>
                    <span className="value">{data?.medium_term}</span>
                  </div>
                </div>
                <span className="label text-blue">Regime tributário</span>
                <div className="table-regime">
                  <div className="cell">
                    <span className={`box ${(data?.regime_tributario === 'simples_nacional' && 'active')}`} />
                    <span className="label">Simples</span>
                  </div>
                  <div className="cell">
                    <span className={`box ${(data?.regime_tributario === 'lucro_real' && 'active')}`} />
                    <span className="label">Lucro real</span>
                  </div>
                  <div className="cell">
                    <span className={`box ${(data?.regime_tributario === 'lucro_presumido' && 'active')}`} />
                    <span className="label">Lucro presumido</span>
                  </div>
                  <div className="cell">
                    <span className={`box ${(data?.regime_tributario === '' && 'active')}`} />
                    <span className="label">Lucro arbitrado</span>
                  </div>
                  <div className="cell">
                    <span className={`box ${(data?.regime_tributario === 'isento' && 'active')}`} />
                    <span className="label">Isento / Imune</span>
                  </div>
                </div>
                <div className="table">
                  <div className="cell">
                    <span className="label">Local e data</span>
                    <span className="value text-upper">{data?.dateLocale}</span>
                  </div>
                </div>
                <div className="signatures">
                  <div className="signature-content">
                    <div className="signature">
                      <img />
                      <span className="line" />
                      <p>Assinatura autorizada da empresa</p>
                    </div>
                  </div>
                  <div className="signature-content">
                    <div className="signature">
                      <img />
                      <span className="line" />
                      <p>Assinatura do contador e CRC</p>
                    </div>
                    <div className="signature">
                      <img />
                      <span className="line" />
                      <p>CPF do contador</p>
                    </div>
                  </div>
                </div>
                <p>
                  ATENÇÃO: Dispensada se optante pelo Simples e faturamento até 4.800.000,00
                  Os dados abaixo são de preenchimento da agência.
                  Visita à empresa
                </p>
                <div className="table-regime">
                  <div className="cell">
                    <span className="box" />
                    <span className="label">Sim</span>
                  </div>
                  <div className="cell">
                    <span className="box" />
                    <span className="label">Não</span>
                  </div>
                </div>
                <div className="signatures">
                  <div className="signature-content">
                    <div className="signature" style={{ width: '500px', marginTop: 25 }}>
                      <img />
                      <span className="line" />
                      <p>Comitê de crédito da Agência - Carimbo(s) e Assinatura(s)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <p>Não há dados para mostrar</p>
    )
  }

  return (
    <LayoutFull headerTitle="Relatório do Banco do Brasil">
      <Row gutter={15}>
        <Col span={24}>
          <Form autoComplete="off" layout="vertical" onFinish={handlerFilter}>
            <Row gutter={15} align="middle">
              <Col span={4}>
                <Form.Item name="date" label="Data" rules={[{ required: true }]}>
                  <RangePicker
                    format="DD/MM/YYYY"
                    placeholder={['Data Inicial', 'Data Final']}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                  <Select
                    labelInValue={false}
                    placeholder="Digite para buscar..."
                    notFoundContent={
                      !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                    }
                    filterOption={false}
                    onSearch={(value) => searchCompanies(value)}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear>
                    {companyList.map((item: any) => (
                      <Option key={item.id} value={item?.id} style={{ textTransform: 'uppercase' }}>
                        {item?.company?.fantasy_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="credit_card" label="Cartão %" rules={[{ required: true }]}>
                  <Input type="number" suffix="%" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="ticket" label="Boletos %" rules={[{ required: true }]}>
                  <Input type="number" suffix="%" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="bank_check" label="Cheques %" rules={[{ required: true }]}>
                  <Input type="number" suffix="%" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="medium_term"
                  label="Prazo médio de pag."
                  rules={[{ required: true }]}>
                  <Input type="number" suffix="dias" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  style={{ marginRight: 20 }}>
                  Buscar
                </Button>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="button"
                  icon={<PrinterOutlined />}
                  onClick={() => handlePrint()}
                >
                  Imprimir
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <DocumentView />
        </Col>
      </Row>
    </LayoutFull>
  );
}
