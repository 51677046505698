import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import api from '../services/api.service';

export interface SubModule {
  description: string;
  icon: string;
  id: number;
  route: string;
  type_permissions: string[];
}

export interface MenuProps {
  description: string;
  icon: string;
  id: number;
  submodules: SubModule[];
}

export type MenuContextProps = {
  menuList: MenuProps[];
};

interface MenuProviderProps {
  children: ReactNode;
}

const MenuContext = createContext<MenuContextProps>({} as MenuContextProps);

export function MenuProvider({ children }: MenuProviderProps) {
  const [menuList, setMenuList] = useState<any>([]);

  const doGetMenu = useCallback(async () => {
    const { data } = await api.get<MenuProps>('menu');
    setMenuList(data);
  }, [setMenuList]);

  useEffect(() => {
    doGetMenu();
  }, [doGetMenu]);

  return <MenuContext.Provider value={{ menuList }}>{children}</MenuContext.Provider>;
}

export function useMenuList() {
  const context = useContext(MenuContext);
  return context;
}
