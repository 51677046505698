import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import { useState } from 'react';
import FeatureImage from '../../assets/images/feature-image.png';
import Logo from '../../assets/images/logo.png';
import InsideError from '../../components/InsideError';
import { useAuth } from '../../contexts/auth.context';
import './styles.css';

export default function Login() {
  const { doLogin } = useAuth();

  const [errors, setErrors] = useState<string | string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handlerOnSubmit = async (data: any) => {
    try {
      setLoading(true);
      await doLogin(data);
    } catch (err: any) {
      setErrors(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="login-container">
        <div className="features">
          <img src={FeatureImage} alt="Ilustração" />
          <h1>Sua contabilidade digital</h1>
          <div className="sc-enrZtP ihJIQH">
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                1
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Tecnologia para garantir sua contabilidade 100% correta</span>
              </span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                2
              </span>
              <span className="sc-btlduw kMTXgk">Gestão de notas fiscais</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                3
              </span>
              <span className="sc-btlduw kMTXgk">Financeiro + Contabilidade</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                4
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Aplicativo financeiro e contábil</span>
              </span>
            </div>
          </div>
        </div>
        <div className="login-form">
          <img src={Logo} alt="Ilustração" />
          <div className="form-wrapper">
            <Form initialValues={{ remember: true }} onFinish={handlerOnSubmit}>
              <h1>
                Bem-vindo ao <span>Portal</span> da <br /> Ativos Digital
              </h1>
              <p>Preencha os campos abaixo para acessar sua conta</p>
              <Form.Item name="email" rules={[{ required: true }]}>
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="E-mail"
                  type="email"
                  size="large"
                />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Sua senha"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="login-form-button"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                    loading
                  }
                  style={{
                    backgroundColor: 'rgb(45, 205, 105)',
                    borderColor: 'rgb(45, 205, 105)',
                    height: 42,
                  }}>
                  <strong>Entrar</strong>
                </Button>
              </Form.Item>
              {Boolean(errors) && <InsideError errors={errors} />}
            </Form>
            <Row gutter={15}>
              <Col span={24}>
                <a href="/signup">
                  <Button type="ghost" size="large">
                    Não tem uma conta? Cadastre-se
                  </Button>
                </a>
              </Col>
              <Col span={24} style={{ paddingTop: 25 }}>
                <a href="/forgot-password">Esqueceu a senha? Recupere aqui</a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
}
