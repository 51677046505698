import { CredentialProps } from '../../models/CredentialsProps';
import api from '../api.service';

export default class Authentication {
  static async signin(credentials: CredentialProps) {
    try {
      const { data } = await api.post('signin', credentials);
      return data;
    } catch (err: any) {
      throw Error(err?.response?.data?.errors);
    }
  }
}
