import { useRef, useState } from 'react';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Spin, message } from 'antd';
import moment from 'moment';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

export function ReportDRE() {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const reportTemplateRef = useRef(null);

  const handlerFilter = async (values: any) => {
    try {
      setLoading(true);
      let periodFilder;
      if (values.date) {
        const [date_start, date_end] = values.date;
        periodFilder = [
          date_start && moment(date_start).format('YYYY-MM-DD'),
          date_end && moment(date_end).format('YYYY-MM-DD'),
        ];
      }

      const { data } = await api.get('report/dre', {
        params: {
          date: periodFilder || undefined,
          company_id: values?.company_id || undefined,
        },
      });
      setData(data);
    } catch (err: any) {
      const errorMessages = err.response.data.errors || [err.message]
      for (const error of errorMessages) {
        message.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const searchCompanies = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      const message = err.response.data.errors || err.message
      message.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async () => {
    window.document.title = 'ativos-digital.relatorio-dre'
    window.print()
  };

  const DocumentView = () => {
    if (loading) {
      return (
        <Row style={{ padding: 100 }}>
          <Col span={24} className="text-center">
            <Spin size="default" />
          </Col>
        </Row>
      )
    }

    if (!loading && data?.data) {
      return (
        <div
          ref={reportTemplateRef}
          className="page-print">
          <div className="report_dre">
            <div className="page-header">
              <div className='avatar'>
                <img src={process.env.REACT_APP_AVATAR_URL + data?.company?.avatar} height={90} />
              </div>
              <div className='header'>
                <div><strong>Razão Social</strong> {data?.company?.social_name}</div>
                <div><strong>CNPJ/CPF:</strong>  {data?.company?.document}</div>
                <div><strong>E-mail:</strong>  {data?.company?.email}</div>
                <div><strong>Período:</strong>  {data?.period?.start} até {data?.period?.end}</div>
              </div>
            </div>
            <div className="page-content">
              <h1>DEMONSTRAÇÃO DE RESULTADO DO EXERCÍCIO (DRE)</h1>
              {(data?.data || []).map((children: any) => Boolean(Math.abs(children.balance)) && (
                <div className="parent-content">
                  <div className="parent-row">
                    <div>{children.description}</div>
                    <div>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(children.balance)}</div>
                  </div>
                  {
                    children?.data?.map((item: any) => Boolean(Math.abs(item.balance)) && (
                      <div className="child-row">
                        <div>{item.description}</div>
                        <div>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.balance)}</div>
                      </div>
                    ))
                  }
                </div>
              ))}

              {data?.data.length &&
                (
                  <div className='signers'>
                    <div className='signer'>
                      <img src={process.env.REACT_APP_SIGNATURES_URL + data.accountant.assignature} />
                      <strong>{data.accountant.social_name}</strong>
                      <p>CPF/CNPJ: {data.accountant.document} - CRC: {data.accountant.crc}</p>
                    </div>
                    <div className='signer'>
                      <img src={process.env.REACT_APP_SIGNATURES_URL + data.partner.assignature} />
                      <strong>{data.partner.social_name}</strong>
                      <p>CPF/CNPJ: {data.partner.document} - Sócio Responsável</p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )
    }

    return (
      <p>Não há dados para mostrar</p>
    )
  }

  return (
    <LayoutFull headerTitle="Relatório Demonstrativo de Resultados (DRE)">
      <Row>
        <Col span={24}>
          <Form autoComplete="off" layout="vertical" onFinish={handlerFilter} className="form-filter">
            <Row gutter={15}>
              <Col span={4}>
                <Form.Item name="date" label="Data" rules={[{ required: true }]}>
                  <RangePicker
                    format="DD/MM/YYYY"
                    placeholder={['Data Inicial', 'Data Final']}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                  <Select
                    labelInValue={false}
                    placeholder="Digite para buscar..."
                    notFoundContent={
                      !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                    }
                    filterOption={false}
                    onSearch={(value) => searchCompanies(value)}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear>
                    {companyList.map((item: any) => (
                      <Option key={item.id} value={item?.company_id} style={{ textTransform: 'uppercase' }}>
                        {item?.company?.fantasy_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  style={{ marginTop: 30, marginRight: 20 }}>
                  Buscar
                </Button>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="button"
                  icon={<PrinterOutlined />}
                  style={{ marginTop: 30, marginRight: 20 }}
                  onClick={() => handlePrint()}
                >
                  Imprimir
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <DocumentView />
        </Col>
      </Row>
    </LayoutFull>
  );
}
