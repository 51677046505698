import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { CredentialProps } from '../models/CredentialsProps';
import Authentication from '../services/Authentication';

export type AuthContextUserProps = {
  name: string;
  role_id: string;
  email: string;
  company_default: {
    fantasy_name: string;
    id: string;
  };
  company_default_id: number;
};

export type AuthContextProps = {
  signed: boolean;
  user: AuthContextUserProps | null;
  doLogin: (values: CredentialProps) => Promise<void>;
  doLogout: () => void;
  setCompany: (id: number) => void;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<AuthContextUserProps>({} as AuthContextUserProps);

  const getDataStoraged = useCallback(() => {
    const storagedUser = localStorage.getItem('@AtivosDigitalWeb:user');
    const storagedToken = localStorage.getItem('@AtivosDigitalWeb:token');

    if (storagedUser && storagedToken) {
      setUser(JSON.parse(storagedUser));
    }
  }, []);

  async function doLogin(credentials: CredentialProps) {
    try {
      const data = await Authentication.signin(credentials);
      setUser(data.user);
      localStorage.setItem('@AtivosDigitalWeb:user', JSON.stringify(data.user));
      localStorage.setItem('@AtivosDigitalWeb:token', data.access_token);
      window.location.href = '/';
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async function doLogout() {
    localStorage.removeItem('@AtivosDigitalWeb:token');
    localStorage.removeItem('@AtivosDigitalWeb:user');
    setUser({} as AuthContextUserProps);
    window.location.href = '/login';
  }

  function setCompany(id: number) {
    const storagedUser = JSON.parse(localStorage.getItem('@AtivosDigitalWeb:user') || '{}');
    storagedUser.company_default_id = id;
    localStorage.setItem('@AtivosDigitalWeb:user', JSON.stringify(storagedUser));
  }

  useEffect(() => {
    getDataStoraged();
  }, [getDataStoraged]);

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user.name),
        user,
        doLogin,
        doLogout,
        setCompany
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
