export const getNameInitials = () => {
  let userStoraged = { name: 'Ativos Digital' };

  if (localStorage.getItem('@AtivosDigitalWeb:user')) {
    userStoraged = JSON.parse(localStorage.getItem('@AtivosDigitalWeb:user') || '');
  }

  const fullName: any = userStoraged.name;

  return fullName.substring(0, 1).toUpperCase();
};
