import { CheckCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Spin, Upload, UploadProps, message, notification } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { useAuth } from '../../contexts/auth.context';

export function ReportImportExportMoviments() {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [loadingButtonExport, setLoadingButtonExport] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [file, setFile] = useState<any>();
  const [errors, setErrors] = useState<string[]>([]);

  const [form] = Form.useForm();
  const { user } = useAuth();

  const searchCompanies = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      const message = err.response.data.errors || err.message
      message.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlerDownloadCsv = async (values: any) => {
    try {
      let periodFilder: any[] = [];
      if (values.date) {
        const [date_start, date_end] = values.date;
        periodFilder = [
          date_start && moment(date_start).format('YYYY-MM-DD'),
          date_end && moment(date_end).format('YYYY-MM-DD'),
        ];
      }

      setLoadingButtonExport(true)
      const { data } = await api.get('report/export-moviments', {
        params: {
          date: periodFilder || undefined,
          company_id: values?.company_id || undefined,
        }, responseType: 'blob'
      })

      if (data.size <= 1) {
        message.error("Não há dados no período selecionado")
        return
      }

      const file = new Blob([data], { type: 'text/csv' });
      const url = URL.createObjectURL(file);
      window.open(url);
    } catch (err: any) {
      message.error(err.message)
    } finally {
      setLoadingButtonExport(false)
    }
  };

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setFileList([]);
    setShowDrawer(true);
  };

  const sendUpload = async (values: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type_file', values.type_file);
      formData.append('company_id', `${user?.company_default_id}`);
      await api.post('report/import-moviments', formData);
      setShowDrawer(false);
      notification.open({
        message: 'Sucesso',
        description: 'Arquivo enviado com sucesso (Aguarde o processamento finalizar)',
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
      });
    } catch (err: any) {
      setErrors(err?.response?.data?.errors);
    } finally {
      form.resetFields();
      setFileList([]);
      setLoading(false);
    }
  };

  const handleUpload = (file: RcFile): boolean => {
    setFile(file);
    return false;
  };

  const handleChange: UploadProps['onChange'] = ({ fileList }) => {
    const file = fileList[0];
    if (file.size && file.type) {
      const typesAvailabled = ['application/x-zip-compressed', 'text/plain', 'text/xml', 'text/csv'].includes(file.type);
      const size = file.size / 1024 / 1024 < 200;

      if (!typesAvailabled) {
        notification.error({
          message: 'Envie somente imagens do tipo: *.zip, *.txt, *.xml ou *.csv',
        });
        return;
      }

      if (!size) {
        notification.error({
          message: 'O arquivo enviado é maior que 200MB',
        });
        return;
      }
      setFileList([file]);
    }
  };

  return (
    <LayoutFull headerTitle="Importação/Exportação de lançamentos">
      <Row>
        <Col span={20}>
          <Form autoComplete="off" layout="vertical" onFinish={handlerDownloadCsv} className="form-filter">
            <Row gutter={15}>
              <Col span={4}>
                <Form.Item name="date" label="Data" rules={[{ required: true }]}>
                  <RangePicker
                    format="DD/MM/YYYY"
                    placeholder={['Data Inicial', 'Data Final']}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                  <Select
                    labelInValue={false}
                    placeholder="Digite para buscar..."
                    notFoundContent={
                      !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                    }
                    filterOption={false}
                    onSearch={(value) => searchCompanies(value)}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear>
                    {companyList.map((item: any) => (
                      <Option key={item.id} value={item?.company_id} style={{ textTransform: 'uppercase' }}>
                        {item?.company?.fantasy_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingButtonExport}
                  icon={<SearchOutlined />}
                  style={{ marginTop: 30, marginRight: 20 }}>
                  Exportar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            onClick={() => openDrawer()}
            style={{ marginTop: 30, marginRight: 20, backgroundColor: 'green', width: '100%' }}>
            Importar
          </Button>
        </Col>
      </Row>

      <Drawer
        title={"Importação de lançamentos"}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={sendUpload}
          initialValues={{
            type_file: 'default',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={24}>
              <Alert message="Envie um arquivo de até 200MB" type="warning" />
            </Col>
            <Col span={24} style={{ marginTop: 24 }}>
              <Form.Item name="file" label="Faça upload do arquivo" rules={[{ required: true }]}>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  multiple={false}
                  maxCount={1}
                  onChange={handleChange}
                  beforeUpload={handleUpload}
                  showUploadList={{ showRemoveIcon: false }}
                  style={{ width: '100%' }}>
                  {fileList.length >= 8 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="type_file" label="Padrão de importação" rules={[{ required: true }]}>
                <Select placeholder="Padrão de importação" allowClear>
                  <Option value="default">Default</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
