import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  MailOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

type FormFilter = {
  document: number;
  name: string;
  role_id: number;
  company_id: number;
};

export function User() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [roleList, setRoleList] = useState<any[]>([]);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
      render: (value: string, record: any) => (
        <div>
          <strong>{record.name}</strong>
          <br />
          <span>
            Contato:
            {record.email}
          </span>
        </div>
      ),
    },
    {
      title: 'Empresa',
      key: 'company',
      dataIndex: 'company',
      sorter: (a: any, b: any) => a.company?.fantasy_name?.length - b.company?.fantasy_name?.length,
      render: (value: string, record: any) => record?.company?.fantasy_name,
    },
    {
      title: 'Perfil',
      key: 'profile',
      dataIndex: 'profile',
      sorter: (a: any, b: any) =>
        a.record?.role?.description.length - b.record?.role?.description.length,
      render: (value: string, record: any) =>
        record?.role && <Tag color="blue">{record?.role?.description}</Tag>,
    },
    {
      title: 'Email confirmado?',
      dataIndex: 'confirm_mail',
      key: 'confirm_mail',
      align: 'center',
      render: (value: boolean, record: any) => {
        if (value) {
          return <Tag color="green">SIM</Tag>;
        }
        return (
          <div>
            <Tag color="red">NÃO</Tag>
            <Popconfirm
              title="Reenviar email de confirmação?"
              onConfirm={() => {
                handlerResendConfirmationEmail(record.email);
              }}
              okText="Sim"
              cancelText="Não">
              <Button type="link" style={{ color: 'red' }}>
                <MailOutlined /> Reenviar
              </Button>
            </Popconfirm>
          </div>
        );
      },
      sorter: (a: any, b: any) => a?.confirm_mail - b?.confirm_mail,
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (value: boolean, record: any) => (
        <Switch
          size="small"
          defaultChecked={Boolean(value)}
          onClick={(value) => handleToggleActive(record.id, +value, record)}
        />
      ),
      sorter: (a: any, b: any) => a?.active - b?.active,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Popconfirm
            title="Confirma o reset de senha?"
            onConfirm={() => handlerForgotPassword(record.email)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'orange' }}>
              <LockOutlined /> Resetar senha
            </Button>
          </Popconfirm>
          <Button type="link" onClick={() => hidrateFormWithValues(record)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage } };
      const { data } = await api.get('user', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    setCurrentPage(1);
    setFilters({
      document: values?.document?.trim() || undefined,
      name: values?.name?.trim() || undefined,
      company_id: values?.company_id || undefined,
      role_id: values?.role_id || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    (values: any) => {
      form.setFieldsValue(values);
      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const handleToggleActive = async (id: string, active: number, record: any) => {
    try {
      record.active = active;
      await api.put(`user/${id}`, record);
      message.success('Registro atualizado com sucesso');
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`user/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    setLoading(true);
    if (values.id) {
      try {
        await api.put(`user/${values.id}`, values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await api.post('user', values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchRole = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('role', {
        params: { description: search },
      });
      setRoleList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchCompany = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerForgotPassword = async (email: string) => {
    try {
      await api.post('forgot-password', { email });
      message.success(`Email de alteração de senha enviado para ${email}`);
    } catch (err: any) {
      message.success(err.message);
    }
  };

  const handlerResendConfirmationEmail = async (email: string) => {
    try {
      await api.put(`confirm-email/resend/${email}`);
      message.success(`Email de confirmação enviado para ${email}`);
    } catch (err: any) {
      message.success(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Usuários">
      <Form
        initialValues={{ date_type: 'provisioned_at' }}
        onFinish={handlerFilter}
        autoComplete="off"
        layout="vertical">
        <Row gutter={15}>
          <Col span={4}>
            <Form.Item name="name" label="Nome">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="document" label="CPF/CNPJ">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="role_id" label="Perfil">
              <Select
                labelInValue={false}
                placeholder="Digite para buscar..."
                notFoundContent={
                  !roleList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                }
                filterOption={false}
                onSearch={(value) => searchRole(value)}
                style={{ width: '100%' }}
                showSearch
                allowClear>
                {roleList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="company_id" label="Empresa">
              <Select
                labelInValue={false}
                placeholder="Digite para buscar..."
                notFoundContent={
                  !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                }
                filterOption={false}
                onSearch={(value) => searchCompany(value)}
                style={{ width: '100%' }}
                showSearch
                allowClear>
                {companyList.map((item: any) => (
                  <Option key={item?.company?.id} value={item?.company?.id}>
                    {item?.company?.fantasy_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={12}>
              <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="document" label="CPF/CNPJ" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="cellphone" label="Celular" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email" label="E-mail" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="role_id" label="Perfil" rules={[{ required: true }]}>
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !roleList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchRole(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {roleList.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchCompany(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {companyList.map((item: any) => (
                    <Option key={item?.company?.id} value={item?.company?.id}>
                      {item?.company?.fantasy_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="company_default_id"
                label="Empresa Padrão"
                rules={[{ required: true }]}>
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchCompany(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {companyList.map((item: any) => (
                    <Option key={item?.company?.id} value={item?.company?.id}>
                      {item?.company?.fantasy_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!form.getFieldValue('id') && (
              <Col span={12}>
                <Form.Item name="password" label="Senha" rules={[{ required: true }]}>
                  <Input type="password" />
                </Form.Item>
              </Col>
            )}
            <Col span={4}>
              <Form.Item name="active" label="Ativo">
                <Select placeholder="Ativo" allowClear>
                  <Option value={1}>Ativo</Option>
                  <Option value={0}>Inativo</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
