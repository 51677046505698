import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

type FormFilter = {
  created_at: string[] | undefined;
  submodule_id: number;
  company_id: number;
  type_permission_id: number;
};

export function GroupPermission() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [subModuleList, setSubModuleList] = useState<any[]>([]);
  const [typePermissionList, setTypePermissionList] = useState([]);
  const [currentPageSelects, setCurrentPageSelects] = useState<any>({
    subModuleCurrentPage: 1,
  });

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
    },
    {
      title: 'Empresa',
      key: 'company',
      dataIndex: 'company',
      sorter: (a: any, b: any) => a.company?.fantasy_name.length - b.company?.fantasy_name.length,
      render: (value: string, record: any) => record?.company?.fantasy_name,
    },
    {
      title: 'Perfil',
      key: 'role',
      dataIndex: 'role',
      sorter: (a: any, b: any) => a.role.description.length - b.role.description.length,
      render: (value: string, record: any) => record?.role?.description,
    },
    {
      title: 'Submódulo',
      key: 'submodule',
      dataIndex: 'submodule',
      sorter: (a: any, b: any) => a.submodule?.description.length - b.submodule?.description.length,
      render: (value: string, record: any) => record?.submodule?.description,
    },
    {
      title: 'Tipo de permissão',
      key: 'type_permission',
      dataIndex: 'type_permission',
      sorter: (a: any, b: any) =>
        a.type_permission?.description.length - b.type_permission?.description.length,
      render: (value: string, record: any) => record?.type_permission?.description,
    },
    {
      title: 'Cadastrado em',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: (a: any, b: any) => a.created_at.length - b.created_at.length,
      render: (value: string, record: any) =>
        value && moment(value).format('DD/MM/YYYY - HH:mm:ss'),
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage } };
      const { data } = await api.get('group-permission', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [date_start, date_end] = values.created_at;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      submodule_id: values.submodule_id || undefined,
      company_id: values.company_id || undefined,
      type_permission_id: values.type_permission_id || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    (values: any) => {
      form.setFieldsValue(values);
      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`group-permission/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    setLoading(true);
    if (values.id) {
      try {
        await api.put(`group-permission/${values.id}`, values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      delete values.id;
      try {
        await api.post('group-permission', values);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchRole = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('role', {
        params: { description: search },
      });
      setRoleList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchCompany = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { fantasy_name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchSubModule = async (search: string, firstLoad: boolean, event?: any) => {
    try {
      if (!firstLoad) {
        setCurrentPageSelects((current: any) => ({
          ...current,
          subModuleCurrentPage: current.subModuleCurrentPage + 1,
        }));
      }

      if (firstLoad) {
        const { data } = await api.get('submodule', {
          params: { page: 1, per_page: 50, description: search || '' },
        });
        setSubModuleList(data);
        setCurrentPageSelects((current: any) => ({
          ...current,
          subModuleCurrentPage: 1,
        }));
      }

      if (
        !loading &&
        event?.target?.scrollTop + event?.target?.offsetHeight === event?.target?.scrollHeight
      ) {
        // event?.target?.scrollTop(0, event?.target?.scrollHeight)
        setTimeout(async () => {
          setLoading(true);
          const { data } = await api.get('submodule', {
            params: {
              page: currentPageSelects.subModuleCurrentPage,
              per_page: 50,
              description: search || '',
            },
          });
          setSubModuleList([...subModuleList, ...data]);
          setLoading(false);
        }, 1000);
      }
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchTypePermission = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('type-permission');
      setTypePermissionList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Grupos de permissões">
      <Form onFinish={handlerFilter} onFinishFailed={() => { }} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={5}>
            <Form.Item name="created_at" label="Data de cadastro">
              <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Data Inicial', 'Data Final']}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={12}>
              <Form.Item name="role_id" label="Perfil">
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !roleList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchRole(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {roleList.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="company_id" label="Empresa">
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchCompany(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {companyList.map((item: any) => (
                    <Option
                      key={item?.company?.id}
                      value={item?.company?.id}>
                      {item?.company?.fantasy_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="submodule_id" label="Submódulo">
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchSubModule(value, true)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  onPopupScroll={(event) => searchSubModule('', false, event)}
                  loading={loading}>
                  {subModuleList.map((item: any) => (
                    <Option key={item?.id} value={item.id}>
                      {item?.description}
                    </Option>
                  ))}
                  {loading && (
                    <Option key="loading" value="loading">
                      Carregando...
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type_permission_id" label="Tipo de Permissão">
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchTypePermission(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {typePermissionList.map((item: any) => (
                    <Option key={item?.id} value={item.id}>
                      {item?.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
