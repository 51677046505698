import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Upload,
  message,
  notification,
} from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import { formatCepMask, formatDateToBr } from '../../utils/masks';
import { useAuth } from '../../contexts/auth.context';
import { onlyNumbers } from '../../utils/sanitize';
import FormSelect from '../../components/FormSelect';

type FormFilter = {
  created_at: string[] | undefined;
  social_name: string;
  code: string;
  document: string;
  qualifier: number;
};

const QualifierOptions = [
  {
    key: 1,
    label: 'Empresário',
  },
  {
    key: 2,
    label: 'Sócio Administrador',
  },
  {
    key: 3,
    label: 'Sócio',
  },
  {
    key: 4,
    label: 'Procurador',
  },
  {
    key: 5,
    label: 'Administrador não sócio',
  },
  {
    key: 6,
    label: 'Presidente',
  },
  {
    key: 7,
    label: 'Tesoureiro',
  },
];

export function Partner() {

  const { user } = useAuth();

  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [cityList, setCityList] = useState<any[]>([]);

  const [modalPhotoVisible, setModalPhotoVisible] = useState<boolean>(false);
  const [modalPhotoUrl, setModalPhotoUrl] = useState<string>('');
  const [previewImage, setPreviewImage] = useState({
    visible: false,
    url: '',
    modalVisible: false,
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileImage, setFileImage] = useState<any>();

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();

  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Assinatura',
      key: 'assignature',
      dataIndex: 'assignature',
      render: (value: string, record: any) => (
        <button onClick={() => handlerImagePreview(record.assignature)}>
          <img
            src={process.env.REACT_APP_SIGNATURES_URL + record?.assignature}
            alt="Ilustração"
            width="90"
            height="90"
          />
        </button>
      ),
    },
    {
      title: 'Nome',
      key: 'social_name',
      dataIndex: 'social_name',
      sorter: (a: any, b: any) => a.social_name.length - b.social_name.length,
    },
    {
      title: 'CPF/CNPJ',
      key: 'document',
      dataIndex: 'document',
      sorter: (a: any, b: any) => a.document.length - b.document.length,
    },
    {
      title: 'Qualificação',
      key: 'qualifier',
      dataIndex: 'qualifier',
      sorter: (a: any, b: any) => a.qualifier.length - b.qualifier.length,
      render: (value: number, record: any) => {
        const qualifier = QualifierOptions?.find((item) => item?.key === value);
        return <span>{qualifier?.label}</span>;
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record.id)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
      const { data } = await api.get('partner', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [date_start, date_end] = values.created_at;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      social_name: values.social_name || undefined,
      code: values.code || undefined,
      document: values.document || undefined,
      qualifier: values.qualifier || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    async (id: number) => {
      const { data } = await api.get(`partner/${id}`);
      if (data.date_in) {
        data.date_in = moment(data.date_in, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
      if (data.date_out) {
        data.date_out = moment(data.date_out, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
      form.setFieldsValue(data);
      if (data.assignature) {
        const assignature: UploadFile = {
          uid: '1',
          name: data.assignature,
          status: 'done',
          url: `${process.env.REACT_APP_SIGNATURES_URL}${data.assignature}`,
        };
        setFileList([assignature]);
      }

      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`partner/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    setLoading(true);

    if (values.date_in) {
      values.date_in = moment(values.date_in, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (values.date_out) {
      values.date_out = moment(values.date_out, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    const formData = new FormData();
    formData.append('company_id', values.company_id);
    formData.append('document', onlyNumbers(values.document));
    formData.append('active', values.active);
    formData.append('is_responsible', values.is_responsible);

    if (values.assignature)
      formData.append('assignature', values.assignature);

    if (values.social_name)
      formData.append('social_name', values.social_name);

    if (values.qualifier)
      formData.append('qualifier', values.qualifier);

    if (values.address)
      formData.append('address', values.address);

    if (values.district)
      formData.append('district', values.district);

    if (values.number)
      formData.append('number', values.number);

    if (values.complement)
      formData.append('complement', values.complement);

    if (values.city_id)
      formData.append('city_id', onlyNumbers(values.city_id));

    if (values.zipcode)
      formData.append('zipcode', onlyNumbers(values.zipcode));

    if (values.telphone)
      formData.append('telphone', onlyNumbers(values.telphone));

    if (values.cellphone)
      formData.append('cellphone', onlyNumbers(values.cellphone));

    if (values.date_in)
      formData.append('date_in', values.date_in);

    if (values.date_out)
      formData.append('date_out', values.date_out);

    if (fileImage) {
      formData.append('photo', fileImage);
    }

    if (values.id) {
      try {
        await api.put(`partner/${values.id}`, formData);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      delete values.id;
      try {
        await api.post('partner', formData);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClosePreviewImage = () => setPreviewImage({ ...previewImage, modalVisible: false });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload da assinatura</div>
    </div>
  );

  const handleUpload = (file: RcFile): boolean => {
    setFileImage(file);
    return false;
  };

  const handleChange: UploadProps['onChange'] = ({ fileList }) => {
    const file = fileList[0];
    if (file.size && file.type) {
      const typesAvailabled = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type);
      const size = file.size / 1024 / 1024 < 1;

      if (!typesAvailabled) {
        notification.error({
          message: 'Envie somente imagens do tipo: PNG, JPG ou JPEG',
        });
        return;
      }

      if (!size) {
        notification.error({
          message: 'O arquivo enviado é maior que 1MB',
        });
        return;
      }
      setFileList([file]);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleOpenPreviewImage = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage({
      ...previewImage,
      modalVisible: true,
      url: file.url || (file.preview as string),
    });
  };

  const handlerImagePreview = (imageUrl: string) => {
    setModalPhotoVisible(true);
    setModalPhotoUrl(process.env.REACT_SIGNATURES_FILES_URL + imageUrl);
  };

  const searchCompany = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { fantasy_name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchCity = async (search: string) => {
    try {
      if (!search) return;
      setLoading(true);
      const { data } = await api.get('cities', {
        params: { description: search },
      });

      setCityList(data)

    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Sócios">
      <Form onFinish={handlerFilter} onFinishFailed={() => { }} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item name="social_name" label="Nome">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="document" label="CPF/CNPJ">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="qualifier" label="Qualificação">
              <Select placeholder="Qualificação" showSearch allowClear labelInValue={false}>
                {QualifierOptions.map((item) => (
                  <Option value={item.key}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={12}>
              <Form.Item name="social_name" label="Nome" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="qualifier" label="Qualificações">
                <Select placeholder="Qualificações" showSearch allowClear labelInValue={false}>
                  {QualifierOptions.map((item) => (
                    <Option value={item.key}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                <FormSelect
                  onSearch={(value: string) => searchCompany(value)}
                  defaultValue={form.getFieldValue('company')?.fantasy_name}
                  sufixLabel='fantasy_name'
                  field={form.getFieldValue('company')}
                  items={companyList}
                  itemIndex="company"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="document" label="CPF/CNPJ" rules={[{ required: true }]}>
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="is_responsible" label="Sócio Responsável?" rules={[{ required: true }]}>
                <Select placeholder="Sócio Responsável?" allowClear>
                  <Option key={1} value={1}>
                    Sim
                  </Option>
                  <Option key={0} value={0}>
                    Não
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="zipcode" label="CEP">
                <Input
                  maxLength={9}
                  onChange={(e) =>
                    formatCepMask(e.target.value, (result) =>
                      form.setFieldsValue({ zipcode: result }),
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="address" label="Endereço">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="district" label="Bairro">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="number" label="Número">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="complement" label="Complemento">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="city_id" label="Cidade">
                <FormSelect
                  onSearch={(value: string) => searchCity(value)}
                  defaultValue={form.getFieldValue('city')?.description}
                  field={form.getFieldValue('city')}
                  items={cityList}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="telphone" label="Telefone">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cellphone" label="Celular">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="date_in" label="Data entrada" rules={[{ required: true }]}>
                <Input
                  onChange={(event) =>
                    formatDateToBr(event.currentTarget.value, (result) =>
                      form.setFieldsValue({ date_in: result }),
                    )
                  }
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="date_out" label="Data Saída">
                <Input
                  onChange={(event) =>
                    formatDateToBr(event.currentTarget.value, (result) =>
                      form.setFieldsValue({ date_out: result }),
                    )
                  }
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="active" label="Ativo" rules={[{ required: true }]}>
                <Select placeholder="Ativo" allowClear>
                  <Option key={1} value={1}>
                    Sim
                  </Option>
                  <Option key={0} value={0}>
                    Não
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Upload
                listType="picture-card"
                fileList={fileList}
                multiple={false}
                maxCount={1}
                onPreview={handleOpenPreviewImage}
                onChange={handleChange}
                beforeUpload={handleUpload}
                showUploadList={{ showRemoveIcon: false }}
                style={{ width: '100%' }}>
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewImage.modalVisible}
                title="Previzualização do documento"
                footer={null}
                onCancel={handleClosePreviewImage}>
                <img alt="example" style={{ width: '100%' }} src={previewImage.url} />
              </Modal>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
      <Modal
        title="Visualização do documento"
        visible={modalPhotoVisible}
        onOk={() => setModalPhotoVisible(false)}
        onCancel={() => setModalPhotoVisible(false)}>
        <img src={modalPhotoUrl} alt="Ilustração" width="100%" />
      </Modal>
    </LayoutFull>
  );
}
