import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
  notification,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

type FormFilter = {
  user_id: number;
  company_id: number;
  active: number;
  supervisor_id: number;
};

export function Organization() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [userList, setUserList] = useState([]);
  const [supervisorList, setSupervisorList] = useState<any[]>([]);

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Empresa',
      key: 'company',
      dataIndex: 'company',
      sorter: (a: any, b: any) => a.company?.fantasy_name.length - b.company?.fantasy_name.length,
      render: (value: string, record: any) => record?.company?.fantasy_name,
    },
    {
      title: 'Usuário',
      key: 'user',
      dataIndex: 'user',
      sorter: (a: any, b: any) => a.user?.name?.length - b.user?.name?.length,
      render: (value: string, record: any) => `${record?.user?.name}`,
    },
    {
      title: 'Supervisor',
      key: 'supervisor',
      dataIndex: 'supervisor',
      sorter: (a: any, b: any) => a.supervisor?.name?.length - b.supervisor?.name?.length,
      render: (value: string, record: any) => `${record.supervisor?.name}`,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage } };
      const { data } = await api.get('organization', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    setCurrentPage(1);
    setFilters({
      active: values?.active,
      company_id: values?.company_id || undefined,
      user_id: values.user_id || undefined,
      supervisor_id: values?.supervisor_id || undefined,
    });
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`organization/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
  };

  const save = async (values: any) => {
    setLoading(true);
    delete values.id;
    try {
      await api.post('organization', values);
      setShowDrawer(false);
      notification.open({
        message: 'Sucesso',
        description: 'Registro inserido com sucesso',
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
      });
      getData();
    } catch (err: any) {
      setErrors(err?.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  const searchCompany = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { fantasy_name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchUser = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('user', { params: { name: search } });
      setUserList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchSupervisor = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('user', {
        params: { filters: { role_id: '' } },
      });
      setSupervisorList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Empresas vinculadas">
      <Form onFinish={handlerFilter} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item name="company_id" label="Empresa">
              <Select
                labelInValue={false}
                placeholder="Digite para buscar..."
                notFoundContent={
                  !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                }
                filterOption={false}
                onSearch={(value) => searchCompany(value)}
                style={{ width: '100%' }}
                showSearch
                allowClear>
                {companyList.map((item: any) => (
                  <Option key={item?.company?.id} value={item?.company?.id}>
                    {item?.company?.fantasy_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="user_id" label="Usuário">
              <Select
                labelInValue={false}
                placeholder="Digite para buscar..."
                notFoundContent={
                  !userList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                }
                filterOption={false}
                onSearch={(value) => searchUser(value)}
                style={{ width: '100%' }}
                showSearch
                allowClear>
                {userList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    <strong>{item.name}</strong>
                    <br />
                    <small>{item.email}</small>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              loading={loading}
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title="Novo cadastro"
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={save}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={24}>
              <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchCompany(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {companyList.map((item: any) => (
                    <Option key={item?.company?.id} value={item.id}>
                      {item?.company?.fantasy_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="user_id" label="Usuário">
                <Select
                  labelInValue={false}
                  placeholder="Digite para buscar..."
                  notFoundContent={
                    !userList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                  }
                  filterOption={false}
                  onSearch={(value) => searchUser(value)}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  {userList.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      <strong>{item.name}</strong>
                      <br />
                      <small>{item.email}</small>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
