import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Spin, message } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

export function ReportBalance() {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const reportTemplateRef = useRef(null);
  const [pdfUrl, setPdfUrl] = useState<string>('')

  const handlerFilter = async (values: any) => {
    try {
      setLoading(true);
      if (!values.date) {
        return;
      }

      let periodFilder: any[] = [];
      const [date_start, date_end] = values.date;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];

      const { data } = await api.get('report/balance/pdf', { params: { date: periodFilder }, responseType: 'blob' })
      const file = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(file);
      setPdfUrl(url);

    } catch (err: any) {
      const errorMessages = err.response.data.errors || [err.message]
      for (const error of errorMessages) {
        message.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const searchCompanies = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('organization', {
        params: { name: search },
      });
      setCompanyList(data);
    } catch (err: any) {
      const message = err.response.data.errors || err.message
      message.error(message);
    } finally {
      setLoading(false);
    }
  };

  const DocumentView = () => {
    if (loading) {
      return (
        <Row style={{ padding: 100 }}>
          <Col span={24} className="text-center">
            <Spin size="default" />
          </Col>
        </Row>
      )
    }

    if (!loading && pdfUrl) {

      return (
        <div
          ref={reportTemplateRef}
          className="page-print report-balancet">
          <div className="page-container">
            <div className="page-content">
              <iframe src={pdfUrl} width="100%" height="900px" />
            </div>
          </div>
        </div>
      )
    }

    return (
      <p>Não há dados para mostrar</p>
    )
  }

  return (
    <LayoutFull headerTitle="Relatório Balanço Patrimonial">
      <Row>
        <Col span={24}>
          <Form autoComplete="off" layout="vertical" onFinish={handlerFilter} className="form-filter">
            <Row gutter={15}>
              <Col span={4}>
                <Form.Item name="date" label="Data" rules={[{ required: true }]}>
                  <RangePicker
                    format="DD/MM/YYYY"
                    placeholder={['Data Inicial', 'Data Final']}
                    allowClear
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                  <Select
                    labelInValue={false}
                    placeholder="Digite para buscar..."
                    notFoundContent={
                      !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                    }
                    filterOption={false}
                    onSearch={(value) => searchCompanies(value)}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear>
                    {companyList.map((item: any) => (
                      <Option key={item.id} value={item?.company_id} style={{ textTransform: 'uppercase' }}>
                        {item?.company?.fantasy_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  style={{ marginTop: 30, marginRight: 20 }}>
                  Buscar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <DocumentView />
        </Col>
      </Row>
    </LayoutFull>
  );
}
