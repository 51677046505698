import { CheckCircleOutlined, FileOutlined, PlusOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Upload,
} from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import { useAuth } from '../../contexts/auth.context';

type FormFilter = {
  created_at: string[] | undefined;
  status: string;
  company_id: number;
};

const Status: any = {
  waiting: { color: 'orange', label: 'Aguardando' },
  finish: { color: 'green', label: 'Finalizado' },
};

export function NotaFiscalImport() {

  const { user } = useAuth();

  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [file, setFile] = useState<any>();
  const [errors, setErrors] = useState<string[]>([]);

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      render: (value: string, record: any) => (
        <>
          <strong>{record?.company?.fantasy_name}</strong>
          <br />
          <small>
            {record?.company?.document} {record?.company?.email}
          </small>
        </>
      ),
    },
    {
      title: 'Criado em',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: (a: any, b: any) => a.created_at.length - b.created_at.length,
      render: (value: string, record: any) =>
        value && moment(value).format('DD/MM/YYYY - HH:mm:ss'),
    },
    {
      title: 'Total de arquivos',
      key: 'total_files',
      dataIndex: 'total_files',
      sorter: (a: any, b: any) => a.total_files.length - b.total_files.length
    },
    {
      title: 'Arquivos processados',
      key: 'processed_files',
      dataIndex: 'processed_files',
      sorter: (a: any, b: any) => a.processed_files.length - b.processed_files.length
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: (a: any, b: any) => a?.status - b?.status,
      render: (value: string, record: any) => {

        let status = { color: Status['waiting'].color, label: Status['waiting'].label }

        if (record.total_files == record.processed_files) {
          status = {
            color: Status['finish'].color,
            label: Status['finish'].label
          }
        }

        return <Tag color={status.color}>{status.label}</Tag>
      },
    },
    // {
    //   title: 'Duração',
    //   dataIndex: 'duration',
    //   key: 'duration',
    //   align: 'center',
    //   render: (value: string, record: any) => {
    //     const start = moment(record.start_at);
    //     const end = record.end_at ? moment(record.end_at) : moment();
    //     const duration = moment.duration(end.diff(start));
    //     return `${duration.days().toFixed(0)} dia(s), 
    //           ${duration.asHours().toFixed(0)} hora(s), 
    //           ${duration.asMinutes().toFixed(0)} minuto(s)`;
    //   },
    // },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Link to={`/registers/nota-fiscal-import-file/${record.id}`}>
            <Button type="link">
              <FileOutlined /> Arquivos
            </Button>
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
      const { data } = await api.get('nota-fiscal-import', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [date_start, date_end] = values.created_at;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      status: values.status || undefined,
      company_id: values.company_id || undefined,
    });
  };

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setFileList([]);
    setShowDrawer(true);
    setDrawerTitle('Nova importação');
  };

  const sendUpload = async (values: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type_file', values.type_file);
      formData.append('company_id', `${user?.company_default_id}`);
      await api.post('nota-fiscal-import', formData);
      setShowDrawer(false);
      notification.open({
        message: 'Sucesso',
        description: 'Arquivo enviado com sucesso (Aguarde o processamento finalizar)',
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
      });
      getData();
    } catch (err: any) {
      setErrors(err?.response?.data?.errors);
    } finally {
      form.resetFields();
      setFileList([]);
      setLoading(false);
    }
  };

  const handleUpload = (file: RcFile): boolean => {
    setFile(file);
    return false;
  };

  const handleChange: UploadProps['onChange'] = ({ fileList }) => {
    const file = fileList[0];
    if (file.size && file.type) {
      const typesAvailabled = ['application/x-zip-compressed', 'text/plain', 'text/xml'].includes(file.type);
      const size = file.size / 1024 / 1024 < 200;

      if (!typesAvailabled) {
        notification.error({
          message: 'Envie somente imagens do tipo: *.zip, *.txt ou *.xml',
        });
        return;
      }

      if (!size) {
        notification.error({
          message: 'O arquivo enviado é maior que 200MB',
        });
        return;
      }
      setFileList([file]);
    }
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`nota-fiscal-import/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Arquivos importados">
      <Form onFinish={handlerFilter} onFinishFailed={() => { }} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={4}>
            <Form.Item name="status" label="Status">
              <Select placeholder="Status" allowClear>
                <Option value="waiting">Aguardando</Option>
                <Option value="finalized">Finalizado</Option>
                <Option value="error">Erro</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="created_at" label="Data de cadastro">
              <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Data Inicial', 'Data Final']}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={sendUpload}
          initialValues={{
            type_file: 'xml',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={24}>
              <Alert message="Envie um arquivo de até 200MB" type="warning" />
            </Col>
            <Col span={24} style={{ marginTop: 24 }}>
              <Form.Item name="file" label="Faça upload do arquivo" rules={[{ required: true }]}>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  multiple={false}
                  maxCount={1}
                  onChange={handleChange}
                  beforeUpload={handleUpload}
                  showUploadList={{ showRemoveIcon: false }}
                  style={{ width: '100%' }}>
                  {fileList.length >= 8 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="type_file" label="Tipo de arquivo" rules={[{ required: true }]}>
                <Select placeholder="Tipo de arquivo" allowClear>
                  <Option value="sped">SPED</Option>
                  <Option value="xml">XML ZIPADO</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
