import {
  EditOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import { useState } from 'react';
import FeatureImage from '../../assets/images/feature-image.png';
import Logo from '../../assets/images/logo.png';
import InsideError from '../../components/InsideError';
import { useAuth } from '../../contexts/auth.context';
import api from '../../services/api.service';
import { onlyNumbers } from '../../utils/sanitize';
import './styles.css';

export default function SignUp() {
  const { doLogin } = useAuth();
  const [errors, setErrors] = useState<string | string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handlerOnSubmit = async (data: any) => {
    try {
      if (data?.password !== data?.confirm_password) {
        setErrors('Senha e confirme senha, devem ser iguais');
      }

      setLoading(true);
      data.document = onlyNumbers(data.document);
      await api.post('signup', data);
      doLogin({ email: data.email, password: data.password });
    } catch (err: any) {
      setErrors(err?.response?.data?.errors || err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="signup-container">
        <div className="features">
          <img src={FeatureImage} alt="Ilustração" />
          <h1>Sua contabilidade digital</h1>
          <div className="sc-enrZtP ihJIQH">
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                1
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Tecnologia para garantir sua contabilidade 100% correta</span>
              </span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                2
              </span>
              <span className="sc-btlduw kMTXgk">Gestão de notas fiscais</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                3
              </span>
              <span className="sc-btlduw kMTXgk">Financeiro + Contabilidade</span>
            </div>
            <div className="sc-bwcZwS lgoedk">
              <span color="#2dcd69" className="sc-ekA-drt dUxFWE css-1uarxw4">
                4
              </span>
              <span className="sc-btlduw kMTXgk">
                <span>Aplicativo financeiro e contábil</span>
              </span>
            </div>
          </div>
        </div>
        <div className="signup-form">
          <img src={Logo} alt="Ilustração" />
          <div className="form-wrapper">
            <h1>
              Bem-vindo ao <span>Portal</span> da Ativos Digital
            </h1>
            <Form
              layout="vertical"
              initialValues={{
                name: '',
                cellphone: '',
                document: '',
                email: '',
                password: '',
              }}
              onFinish={handlerOnSubmit}
              autoComplete="off"
            >
              <div className="steps-content">
                <Row gutter={15}>
                  <Col span={24}>
                    <Form.Item
                      name="name"
                      rules={[{ required: true }]}
                      label="Nome completo">
                      <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Nome completo"
                        type="text"
                        size="large"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="cellphone" rules={[{ required: true, max: 11 }]} label="Celular (Whatsapp)">
                      <Input
                        prefix={<WhatsAppOutlined className="site-form-item-icon" />}
                        placeholder="Contato (whatsapp)"
                        type="number"
                        size="large"
                        autoComplete="off"
                        maxLength={11}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="document" rules={[{ required: true }]} label="CPF">
                      <Input
                        prefix={<EditOutlined className="site-form-item-icon" />}
                        placeholder="CPF"
                        type="number"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="email" rules={[{ required: true }]} label="E-mail">
                      <Input
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="Seu melhor email"
                        type="email"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="password" rules={[{ required: true }]} label="Senha">
                      <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Sua senha"
                        type="password"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="confirm_password"
                      rules={[{ required: true }]}
                      label="Confirme sua senha">
                      <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Repita a senha"
                        type="password"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      block
                      className="signup-form-button"
                      htmlType="submit"
                      style={{
                        backgroundColor: 'rgb(45, 205, 105)',
                        borderColor: 'rgb(45, 205, 105)',
                        height: 42,
                      }}
                      loading={loading}>
                      <strong>Enviar</strong>
                    </Button>
                  </Col>
                </Row>
              </div>
              {Boolean(errors) && <InsideError errors={errors} />}
              <a href="/login">Já tem uma conta? Faça login</a>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
