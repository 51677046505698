import { DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

type FormFilter = {
  created_at: string[] | undefined;
  access_key: string;
  origin: string;
  canceled: boolean;
  number: number;
};

export function NotaFiscal() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Número',
      key: 'number',
      dataIndex: 'number',
      sorter: (a: any, b: any) => a.number.length - b.number.length,
    },
    {
      title: 'Chave de Acesso',
      key: 'access_key',
      dataIndex: 'access_key',
      sorter: (a: any, b: any) => a.access_key.length - b.access_key.length,
    },
    {
      title: 'Data Emissão',
      key: 'emission_at',
      dataIndex: 'emission_at',
      sorter: (a: any, b: any) => a.emission_at.length - b.emission_at.length,
      render: (value: string, record: any) =>
        value && moment(value).format('DD/MM/YYYY - HH:mm:ss'),
    },
    {
      title: 'Modelo',
      key: 'model',
      dataIndex: 'model',
      sorter: (a: any, b: any) => a.model.length - b.model.length,
    },
    {
      title: 'Série',
      key: 'serie',
      dataIndex: 'serie',
      sorter: (a: any, b: any) => a.serie.length - b.serie.length,
    },
    {
      title: 'Valor',
      key: 'amount',
      dataIndex: 'amount',
      sorter: (a: any, b: any) => a.amount.length - b.amount.length,
      render: (value: string, record: any) => `R$ ${record.amount}`,
    },
    {
      title: 'Cancelada',
      key: 'canceled',
      dataIndex: 'canceled',
      sorter: (a: any, b: any) => a.canceled.length - b.canceled.length,
      render: (value: string, record: any) =>
        record.canceled ? <Tag color="red">Cancelada</Tag> : <Tag color="green">Normal</Tag>,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Link to={`/fiscal/nfes/${record.id}`}>
            <Button type="link">
              <EyeOutlined /> Detalhes
            </Button>
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage } };
      const { data } = await api.get('nfe', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [date_start, date_end] = values.created_at;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      access_key: values.access_key || undefined,
      origin: values.origin || undefined,
      canceled: values.canceled || undefined,
      number: values.number || undefined,
    });
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`nfe/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Notas Fiscais">
      <Form onFinish={handlerFilter} onFinishFailed={() => {}} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item name="access_key" label="Chave de Acesso">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="number" label="Número da NFe">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="origin" label="Tipo de importação">
              <Select placeholder="Tipo de importação" allowClear>
                <Option value="import_xml">XML</Option>
                <Option value="import_sped">SPED</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="created_at" label="Data de cadastro">
              <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Data Inicial', 'Data Final']}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="canceled" label="Canceladas">
              <Select placeholder="Canceladas" allowClear>
                <Option value="1">Somente canceladas</Option>
                <Option value="0">Somente não canceladas</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
    </LayoutFull>
  );
}
