import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { ConfirmEmail } from '../screens/ConfirmEmail';
import ForgotPassword from '../screens/ForgotPassword';
import Login from '../screens/Login';
import SignUp from '../screens/SignUp';

export function AuthRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/confirm-email/:hash" component={ConfirmEmail} />
      </Switch>
    </Router>
  );
}
