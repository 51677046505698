import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import { useAuth } from '../../contexts/auth.context';
import TextArea from 'antd/lib/input/TextArea';
import FormSelect from '../../components/FormSelect';

type FormFilter = {
  code: number;
  description: string;
  active: boolean;
  cnae_id: number;
};

export function Service() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const { user } = useAuth();

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
    },
    {
      title: 'Código Tributário',
      key: 'tributation_code',
      dataIndex: 'tributation_code',
      sorter: (a: any, b: any) => a.tributation_code.length - b.tributation_code.length,
    },
    {
      title: 'Subtitem',
      key: 'code',
      dataIndex: 'code',
      sorter: (a: any, b: any) => a.code.length - b.code.length,
    },
    {
      title: 'Descrição',
      key: 'description',
      dataIndex: 'description',
      sorter: (a: any, b: any) => a.description.length - b.description.length,
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (value: boolean, record: any) => (
        <Switch
          size="small"
          defaultChecked={Boolean(value)}
          onClick={(value) => handleToggleActive(record.id, +value, record)}
        />
      ),
      sorter: (a: any, b: any) => a?.active - b?.active,
    },
    {
      title: 'Cadastrado em',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: (a: any, b: any) => a.created_at.length - b.created_at.length,
      render: (value: string, record: any) =>
        value && moment(value).format('DD/MM/YYYY - HH:mm:ss'),
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
      const { data } = await api.get('services', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    setCurrentPage(1);
    setFilters({
      code: values?.code || undefined,
      description: values?.description || undefined,
      active: values?.active,
      cnae_id: values?.cnae_id,
    });
  };

  const hidrateFormWithValues = useCallback(
    (values: any) => {
      form.setFieldsValue(values);
      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const handleToggleActive = async (id: string, active: number, record: any) => {
    try {
      record.active = active;
      await api.put(`services/${id}`, record);
      message.success('Registro atualizado com sucesso');
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`services/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    setLoading(true);
    if (values.id) {
      try {
        await api.put(`services/${values.id}`, values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        delete values.id;
        values.company_id = user?.company_default_id
        await api.post('services', values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Cadastro de serviços">
      <Form onFinish={handlerFilter} onFinishFailed={() => { }} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={3}>
            <Form.Item name="code" label="Subitem">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="tributation_code" label="Código Tributação">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="description" label="Descrição">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="active" label="Ativo">
              <Select placeholder="Ativo" allowClear>
                <Option value="1">Ativos</Option>
                <Option value="0">Inativos</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
            active: 1,
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={8}>
              <Form.Item name="tributation_code" label="Código Tributação" rules={[{ required: true, min: 6, max: 6 }]}>
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="code" label="Subitem" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="active" label="Ativo" rules={[{ required: true }]}>
                <Select placeholder="Ativo" allowClear>
                  <Option key={1} value={1}>
                    Sim
                  </Option>
                  <Option key={0} value={0}>
                    Não
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" label="Descrição" rules={[{ required: true }]}>
                <TextArea rows={10} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
