import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
  notification,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import FormSelect from '../../components/FormSelect';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import { useAuth } from '../../contexts/auth.context';

type FormFilter = {
  created_at: string[] | undefined;
  description: string;
  cfop: string;
  debit_account_id: number;
  credit_account_id: number;
  historic_id: number;
  payment_type_id: number;
};

export function CFOPXml() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [accountPlanList, setAccountPlanList] = useState<any[]>([]);
  const [historicList, setHistoricList] = useState<any>([]);
  const [paymentTypeList, setPaymentTypeList] = useState<any>([]);
  const { user } = useAuth();

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const { Option } = Select;

  const whereIsValue: Record<string, string>[] = [
    {
      label: "Campo Valor do Pagamento",
      value: "payment_value"
    },
    {
      label: "Campo Total da Nota",
      value: "nfe_value"
    },
    {
      label: "Campo Total de Produtos",
      value: "products_value"
    }
  ]

  const columns: any[] = [
    {
      title: 'CFOP',
      key: 'cfop',
      dataIndex: 'cfop',
      sorter: (a: any, b: any) => a.cfop.length - b.cfop.length,
    },
    {
      title: 'Conta Débito',
      key: 'debit_account_id',
      dataIndex: 'debit_account_id',
      sorter: (a: any, b: any) => a.debit_account_id.length - b.debit_account_id.length,
      render: (value: string, record: any) => {
        return (
          <p>{record?.debit_account?.account} - {record?.debit_account?.description}</p>
        )
      },
    },
    {
      title: 'Conta Crédito',
      key: 'credit_account_id',
      dataIndex: 'credit_account_id',
      sorter: (a: any, b: any) => a.credit_account_id.length - b.credit_account_id.length,
      render: (value: string, record: any) => {
        return (
          <p>{record?.credit_account?.account} - {record?.credit_account?.description}</p>
        )
      },
    },
    {
      title: 'Histórico',
      key: 'historic_id',
      dataIndex: 'historic_id',
      sorter: (a: any, b: any) => a.historic_id.length - b.historic_id.length,
      render: (text: any, record: any) => record.historic?.description,
    },
    {
      title: 'Tipo de pagamento',
      key: 'payment_type_id',
      dataIndex: 'payment_type_id',
      sorter: (a: any, b: any) => a.payment_type_id.length - b.payment_type_id.length,
      render: (text: any, record: any) => record?.payment_type?.description,
    },
    // {
    //   title: 'Onde está o valor?',
    //   key: 'where_is_value',
    //   dataIndex: 'where_is_value',
    //   sorter: (a: any, b: any) => a.where_is_value.length - b.where_is_value.length,
    //   render: (text: any, record: any) => whereIsValue.find(item => item.value === text)?.label,
    // },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
      const { data } = await api.get('cfop-xml', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [date_start, date_end] = values.created_at;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      description: values.description || undefined,
      cfop: values.cfop || undefined,
      debit_account_id: values.debit_account_id || undefined,
      credit_account_id: values.credit_account_id || undefined,
      historic_id: values.historic_id || undefined,
      payment_type_id: values.payment_type_id || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    (values: any) => {
      form.setFieldsValue(values);
      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`cfop-xml/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    setLoading(true);
    if (values.id) {
      try {
        await api.put(`cfop-xml/${values.id}`, values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        delete values.id;
        values.company_id = user?.company_default_id
        await api.post('cfop-xml', values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchAccountPlan = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('account-plan', {
        params: { description: search, company_id: user?.company_default_id },
      });
      setAccountPlanList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchHistoric = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('historic', { params: { description: search, company_id: user?.company_default_id } });
      setHistoricList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchPaymentType = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('payment-type', {
        params: { description: search },
      });
      setPaymentTypeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerImport = async () => {
    try {
      setLoading(true);
      await api.get('cfop-xml/import-default');
      getData();
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Utilitários - Parametrização CFOP XML">
      <Form onFinish={handlerFilter} onFinishFailed={() => { }} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={2}>
            <Form.Item name="cfop" label="CFOP">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="debit_account_id" label="Conta Débito">
              <FormSelect
                onSearch={(value: string) => searchAccountPlan(value)}
                items={accountPlanList}
                prefixLabel="account"
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="credit_account_id" label="Conta Crédito">
              <FormSelect
                onSearch={(value: string) => searchAccountPlan(value)}
                items={accountPlanList}
                prefixLabel="account"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="historic_id" label="Histórico">
              <FormSelect
                onSearch={(value: string) => searchHistoric(value)}
                items={historicList}
                prefixLabel="code"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="payment_type_id" label="Tipo de pagamento">
              <FormSelect
                onSearch={(value: string) => searchPaymentType(value)}
                items={paymentTypeList}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              onClick={() => handlerImport()}
              style={{ marginTop: 30 }}
              disabled={Boolean(dataTable.length || loading)}>
              <PlusOutlined /> Importar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={24}>
              <Form.Item name="cfop" label="CFOP" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="debit_account_id" label="Conta Débito">
                <FormSelect
                  onSearch={(value: string) => searchAccountPlan(value)}
                  defaultValue={form.getFieldValue('debit_account')?.description}
                  items={accountPlanList}
                  field={form.getFieldValue('debit_account')}
                  prefixLabel="account"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="credit_account_id" label="Conta Crédito">
                <FormSelect
                  onSearch={(value: string) => searchAccountPlan(value)}
                  defaultValue={form.getFieldValue('credit_account')?.description}
                  items={accountPlanList}
                  field={form.getFieldValue('credit_account')}
                  prefixLabel="account"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="historic_id" label="Histórico">
                <FormSelect
                  onSearch={(value: string) => searchHistoric(value)}
                  defaultValue={form.getFieldValue('historic')?.description}
                  items={historicList}
                  field={form.getFieldValue('historic')}
                  prefixLabel="code"
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="payment_type_id" label="Tipo de pagamento">
                <FormSelect
                  onSearch={(value: string) => searchPaymentType(value)}
                  defaultValue={form.getFieldValue('payment_type')?.description}
                  items={paymentTypeList}
                  field={form.getFieldValue('payment_type')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="model" label="Modelo">
                <Select
                  labelInValue={false}
                  filterOption={false}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  <Option value={55}>55</Option>
                  <Option value={65}>65</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="dinamic_people" label="Conta pessoa automático">
                <Select
                  labelInValue={false}
                  filterOption={false}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear>
                  <Option value={1}>Sim</Option>
                  <Option value={0}>Não</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item name="where_is_value" label="Onde está o pagamento?">
                <Select>
                  {whereIsValue.map(item => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
