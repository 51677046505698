import {
    CheckCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {
    Alert,
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Table
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import FormSelect from '../../components/FormSelect';
import LayoutFull from '../../components/LayoutFull';
import { useAuth } from '../../contexts/auth.context';
import api from '../../services/api.service';
import { formatDateToBr } from '../../utils/masks';

type FormFilter = {
    code: number;
    search_field: string;
    search_text: string;
    company_id: number;
    active: number;
    type: string;
};

export function ServiceNotaFiscal() {
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [peopleList, setPeopleList] = useState<any>([]);
    const [companyList, setCompanyList] = useState<any>([]);
    const [cityList, setCityList] = useState<any>([]);
    const [companyServiceList, setCompanyServiceList] = useState<any>([]);
    const [nfsePdfStream, setNfsePdfStream] = useState<any>('');
    const [isModalPdfOpen, setIsModalPdfOpen] = useState(false);

    const { user } = useAuth();

    const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

    const [form] = Form.useForm();
    const [formFilter] = Form.useForm();
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const columns: any[] = [
        {
            title: 'Id',
            key: 'id',
            dataIndex: 'id',
            sorter: (a: any, b: any) => a.id.length - b.id.length,
        },
        {
            title: 'Empresa',
            key: 'company',
            dataIndex: 'company',
            sorter: (a: any, b: any) => a.company.length - b.company.length,
            render: (value: string, record: any) => (
                <div>
                    <span>{record?.company?.social_name}</span>
                    <br />
                    <strong>{record?.company?.document}</strong>
                    <br />
                </div>
            ),
        },
        {
            title: 'Tomador',
            key: 'people',
            dataIndex: 'people',
            sorter: (a: any, b: any) => a.people.length - b.people.length,
            render: (value: string, record: any) => (
                <div>
                    <span>{record?.people?.social_name}</span>
                    <br />
                    <strong>{record?.people?.document}</strong>
                    <br />
                </div>
            ),
        },
        {
            title: 'Emissão',
            dataIndex: 'emission_at',
            key: 'emission_at',
            align: 'center',
            render: (value: string, record: any) => moment(value).format('DD/MM/YYYY'),
            sorter: (a: any, b: any) => a?.type - b?.type,
        },
        {
            title: 'Cadastrado em',
            key: 'created_at',
            dataIndex: 'created_at',
            sorter: (a: any, b: any) => a.created_at.length - b.created_at.length,
            render: (value: string, record: any) =>
                value && moment(value).format('DD/MM/YYYY - HH:mm:ss'),
        },
        {
            title: 'Ações',
            key: 'actions',
            align: 'center',
            render: (text: any, record: any) => (
                <Space size="small">
                    <Button type="link" onClick={() => getNfseStream(record)}>
                        <EditOutlined /> Detalhes
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Deseja realmente excluir esse item?"
                        onConfirm={() => remove(record.id)}
                        okText="Sim"
                        cancelText="Não">
                        <Button type="link" style={{ color: 'red' }}>
                            <DeleteOutlined /> Excluir
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const getData = useCallback(async () => {
        try {
            setLoading(true);
            const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
            const { data } = await api.get('nfse', { params });
            setDataTable(data);
        } catch (err: any) {
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    }, [currentPage, filters]);

    const handlerFilter = async (values: any) => {
        setCurrentPage(1);
        setFilters({
            code: values.code || undefined,
            search_field: values.search_field || undefined,
            search_text: values.search_text || undefined,
            active: values?.active,
            company_id: values.company_id || undefined,
            type: values.type || undefined,
        });
    };

    const remove = useCallback(
        async (id: string) => {
            try {
                await api.delete(`people/${id}`);
                message.success('Registro excluido com sucesso');
                getData();
            } catch (err: any) {
                message.error(err.message);
            }
        },
        [getData],
    );

    const openDrawer = () => {
        setErrors([]);
        form.resetFields();
        setShowDrawer(true);
        setDrawerTitle('Emitir Nota Fiscal de Serviço');
    };

    const saveOrCreate = async (values: any) => {
        setLoading(true);
        setErrors([])
        try {

            //Recupera o objeto de people
            values.people = peopleList.find((item: any) => item.id === values.people_id)

            //Recupera o objeto da cidade
            values.city = cityList.find((item: any) => item.id === values.service_code_city)

            values.emission_at = moment(values.emission_at, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss')
            await api.post('nfse', values);
            notification.open({
                message: 'Sucesso',
                description: 'Registro inserido com sucesso',
                icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            });
            getData();
        } catch (err: any) {
            setErrors(err?.response?.data?.errors);
        } finally {
            setLoading(false);
        }
    };

    const searchCompany = async (search: string) => {
        try {
            setLoading(true);
            const { data } = await api.get('organization', {
                params: { fantasy_name: search },
            });
            setCompanyList(data);
        } catch (err: any) {
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const searchPeople = async (search: string) => {
        try {
            setLoading(true);
            const { data } = await api.get('people', {
                params: { name: search, company_id: user?.company_default_id },
            });
            setPeopleList(data);
        } catch (err: any) {
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const searchCity = async (search: string) => {
        try {
            setLoading(true);
            const { data } = await api.get('cities', {
                params: { description: search },
            });
            setCityList(data);
        } catch (err: any) {
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const searchCompanyServices = async (search: string) => {
        try {
            setLoading(true);
            const { data } = await api.get('company-services', {
                params: { description: search },
            });
            setCompanyServiceList(data);
        } catch (err: any) {
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const getNfseStream = async (nfse: any) => {
        try {
            const { data } = await api.get('nfse/getPdf', { params: { company_id: nfse.company_id, access_key: nfse.access_key } });
            setIsModalPdfOpen(true)
            const pdf = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(pdf);
            setNfsePdfStream(url)
        } catch (err) {

        }
    }

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <LayoutFull headerTitle="Notas Fiscais de Serviço">
            <Form onFinish={handlerFilter} onFinishFailed={() => { }} autoComplete="off" layout="vertical" form={formFilter}>
                <Row gutter={15}>
                    <Col span={4}>
                        <Form.Item name="company_id" label="Empresa">
                            <Select
                                labelInValue={false}
                                placeholder="Digite para buscar..."
                                notFoundContent={
                                    !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                                }
                                filterOption={false}
                                onSearch={(value) => searchCompany(value)}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear>
                                {companyList.map((item: any) => (
                                    <Option
                                        key={item?.company?.id}
                                        value={item?.company?.id}>
                                        {item?.company?.fantasy_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="people_id" label="Tomador">
                            <FormSelect
                                onSearch={(value: string) => searchPeople(value)}
                                items={peopleList}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="emission_at" label="Data emissão">
                            <RangePicker
                                format="DD/MM/YYYY"
                                placeholder={['Data Inicial', 'Data Final']}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button
                            type="default"
                            htmlType="submit"
                            icon={<SearchOutlined />}
                            style={{ marginTop: 30 }}>
                            Buscar
                        </Button>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
                            <PlusOutlined /> Novo
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Table
                columns={columns}
                dataSource={dataTable}
                rowKey={() => Math.random()}
                loading={loading}
                pagination={false}
                tableLayout="auto"
            />
            <CustomPagination
                onPrevious={() => setCurrentPage(currentPage - 1)}
                onNext={() => setCurrentPage(currentPage + 1)}
                currentPage={currentPage}
                dataTableLength={Boolean(dataTable.length)}
            />
            <Drawer
                title={drawerTitle}
                width={720}
                onClose={() => setShowDrawer(false)}
                maskClosable={false}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}>
                <Form
                    layout="vertical"
                    form={form}
                    autoComplete="off"
                    onFinish={saveOrCreate}
                    initialValues={{
                        id: '',
                    }}>
                    <Row gutter={15}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly name="id" />
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item name="company_id" label="Empresa" rules={[{ required: true }]}>
                                <Select
                                    labelInValue={false}
                                    placeholder="Digite para buscar..."
                                    notFoundContent={
                                        !companyList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                                    }
                                    filterOption={false}
                                    onSearch={(value) => searchCompany(value)}
                                    style={{ width: '100%' }}
                                    showSearch
                                    allowClear>
                                    {companyList.map((item: any) => (
                                        <Option
                                            key={item?.company?.id}
                                            value={item?.company?.id}>
                                            {item?.company?.fantasy_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="people_id" label="Tomador" rules={[{ required: true }]}>
                                <FormSelect
                                    onSearch={(value: string) => searchPeople(value)}
                                    items={peopleList}
                                    sufixLabel="fantasy_name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="emission_at" label="Data" rules={[{ required: true }]}>
                                <Input
                                    onChange={(event) =>
                                        formatDateToBr(event.currentTarget.value, (result) =>
                                            form.setFieldsValue({ emission_at: result }),
                                        )
                                    }
                                    maxLength={10}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="service_code_city" label="Código Municipio" rules={[{ required: true }]}>
                                <FormSelect
                                    onSearch={(value: string) => searchCity(value)}
                                    items={cityList}
                                    prefixLabel='code'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <Row>
                                        <Col span={24}>
                                            {fields.map((field: any, index: number) => (
                                                <Row gutter={15} key={field.key}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name={[index, 'company_service_id']}
                                                            label="Serviço"
                                                        >
                                                            <Select
                                                                labelInValue={false}
                                                                placeholder="Digite para buscar..."
                                                                notFoundContent={
                                                                    !companyServiceList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                                                                }
                                                                filterOption={false}
                                                                onSearch={(value) => searchCompanyServices(value)}
                                                                style={{ width: '100%' }}
                                                                showSearch
                                                                allowClear>
                                                                {companyServiceList.map((item: any) => (
                                                                    <Option
                                                                        key={item?.id}
                                                                        value={item?.id}>
                                                                        {item?.service_description}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            label="Valor (R$)"
                                                            name={[index, 'service_value']}
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Button
                                                            htmlType="button"
                                                            type="text"
                                                            style={{ marginTop: 31, width: '100%', backgroundColor: 'red', color: "#fff" }}
                                                            onClick={() => remove(index)}
                                                        >
                                                            Deletar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                        <Col span={24} style={{ marginTop: 22, marginBottom: 22 }}>
                                            <Button onClick={() => add()} style={{ width: '100%' }}>
                                                Incluir serviços
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col span={4}>
                            <Button
                                onClick={() => setShowDrawer(false)}
                                style={{ marginRight: 8 }}
                                block
                                htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button type="primary" htmlType="submit" block loading={loading}>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <ul style={{ listStyle: 'none' }}>
                    {errors?.length > 0 &&
                        errors.map((item: any) => (
                            <li>
                                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
                            </li>
                        ))}
                </ul>
            </Drawer>
            <Modal title="PDF" visible={isModalPdfOpen} onOk={() => setIsModalPdfOpen(false)}>
                <iframe src={nfsePdfStream} />
            </Modal>
        </LayoutFull>
    );
}
