import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  message,
  notification,
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../styles/custom.css';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import FormSelect from '../../components/FormSelect';
import { useAuth } from '../../contexts/auth.context';

const { Option } = Select;

type FormFilter = {
  created_at: string[] | undefined;
  name: string;
  description: string;
  status: string;
  city_id: number;
  occupation_id: number;
  working_hour_id: number;
  salary_range_id: number;
};

export function Jobs() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [cityList, setCityList] = useState<any[]>([]);
  const [occupationList, setOccupationList] = useState<any[]>([]);
  const [salaryRangeList, setSalaryRangeList] = useState<any[]>([]);
  const [workingHoursList, setWorkingHoursList] = useState<any[]>([]);
  const [valueEditorText, setValueEditorText] = useState('');
  const { user } = useAuth();

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();

  type StatusLabel = {
    label: string;
    color: string;
    text: string;
  };

  const StatusLabelList = [
    {
      label: 'hiring',
      color: 'green',
      text: 'Contratando',
    },
    {
      label: 'closed',
      color: 'gray',
      text: 'Fechado',
    },
  ];

  const columns: any[] = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (a: any, b: any) => a.status.length - b.status.length,
      render: (value: string, record: any) => {
        const status = StatusLabelList.find((item) => item.label === value && item);
        return <Tag color={status?.color}>{status?.text}</Tag>;
      },
    },
    {
      title: 'Cidade',
      key: 'city_id',
      dataIndex: 'city_id',
      sorter: (a: any, b: any) => a.city_id.length - b.city_id.length,
      render: (value: string, record: any) => `${record?.city?.description}`,
    },
    {
      title: 'Ocupação',
      key: 'occupation',
      dataIndex: 'occupation',
      sorter: (a: any, b: any) => a.occupation.length - b.occupation.length,
      render: (value: string, record: any) => `${record?.occupation?.description}`,
    },
    {
      title: 'Faixa salarial',
      key: 'salary_range',
      dataIndex: 'salary_range',
      sorter: (a: any, b: any) => a.salary_range.length - b.salary_range.length,
      render: (value: string, record: any) => `${record?.salary_range?.description}`,
    },
    {
      title: 'Carga horaria',
      key: 'working_hour',
      dataIndex: 'working_hour',
      sorter: (a: any, b: any) => a.working_hour.length - b.working_hour.length,
      render: (value: string, record: any) => `${record?.working_hour?.description}`,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Link to={`/registers/job/${record.id}/candidate-jobs`}>
            <Button type="link" onClick={() => hidrateFormWithValues(record)}>
              Candidaturas
            </Button>
          </Link>
          <Divider type="vertical" />
          <Button type="link" onClick={() => hidrateFormWithValues(record)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
      const { data } = await api.get('jobs', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [dateStart, dateEnd] = values.created_at;
      periodFilder = [
        dateStart && moment(dateStart).format('YYYY-MM-DD'),
        dateEnd && moment(dateEnd).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      description: values.description || undefined,
      status: values.status || undefined,
      city_id: values.city_id || undefined,
      name: values.name || undefined,
      occupation_id: values.occupation_id || undefined,
      working_hour_id: values.working_hour_id || undefined,
      salary_range_id: values.salary_range_id || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    (values: any) => {
      form.setFieldsValue(values);
      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`jobs/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {
    setLoading(true);
    if (values.id) {
      try {
        await api.put(`jobs/${values.id}`, values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        delete values.id;
        values.company_id = user?.company_default_id;
        await api.post('jobs', values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchOccupation = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('occupation', {
        params: { description: search },
      });
      setOccupationList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchSalaryRange = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('salary-range', {
        params: { description: search },
      });
      setSalaryRangeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchCity = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('cities', {
        params: { description: search },
      });
      setCityList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchWorkingHours = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('working-hours', {
        params: { description: search },
      });
      setWorkingHoursList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Cadastro de vagas">
      <Form onFinish={handlerFilter} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={3}>
            <Form.Item name="name" label="Nome">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="status" label="Status">
              <Select
                labelInValue={false}
                placeholder="Digite para buscar..."
                style={{ width: '100%' }}
                showSearch
                allowClear>
                {StatusLabelList.map((item: StatusLabel) => (
                  <Option key={item.label} value={item.label}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="city_id" label="Cidade">
              <Select
                onSearch={(value) => searchCity(value)}
                showSearch
                notFoundContent={
                  !cityList?.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>
                }
                labelInValue={false}
                placeholder="Selecione uma opção"
                style={{ width: '100%' }}
                allowClear>
                {cityList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="occupation_id" label="Ocupação">
              <Select
                onSearch={(value) => searchOccupation(value)}
                showSearch
                notFoundContent={
                  !occupationList?.length ? (
                    <Spin size="small" />
                  ) : (
                    <div>Nenhum dado encontrado</div>
                  )
                }
                labelInValue={false}
                placeholder="Selecione uma opção"
                style={{ width: '100%' }}
                allowClear>
                {occupationList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="salary_range_id" label="Faixa salárial">
              <Select
                onSearch={(value) => searchSalaryRange(value)}
                showSearch
                notFoundContent={
                  !salaryRangeList?.length ? (
                    <Spin size="small" />
                  ) : (
                    <div>Nenhum dado encontrado</div>
                  )
                }
                labelInValue={false}
                placeholder="Selecione uma opção"
                style={{ width: '100%' }}
                allowClear>
                {salaryRangeList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="working_hour_id" label="Carga horaria">
              <Select
                onSearch={(value) => searchWorkingHours(value)}
                showSearch
                notFoundContent={
                  !workingHoursList?.length ? (
                    <Spin size="small" />
                  ) : (
                    <div>Nenhum dado encontrado</div>
                  )
                }
                labelInValue={false}
                placeholder="Selecione uma opção"
                style={{ width: '100%' }}
                allowClear>
                {workingHoursList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              loading={loading}
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={1} />
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={23}>
              <Form.Item name="description" label="Descrição" rules={[{ required: true }]}>
                <ReactQuill theme="snow" value={valueEditorText} onChange={setValueEditorText} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status" label="Status">
                <Select
                  labelInValue={false}
                  placeholder="Selecione uma opção"
                  style={{ width: '100%' }}
                  allowClear>
                  {StatusLabelList.map((item: StatusLabel) => (
                    <Option key={item.label} value={item.label}>
                      {item.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="city_id" label="Cidade">
                <FormSelect
                  onSearch={(value: string) => searchCity(value)}
                  defaultValue={form.getFieldValue('city')?.description}
                  items={cityList}
                  field={form.getFieldValue('city')}
                  prefixLabel="code"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="occupation_id" label="Ocupação">
                <FormSelect
                  onSearch={(value: string) => searchOccupation(value)}
                  defaultValue={form.getFieldValue('occupation')?.description}
                  items={occupationList}
                  field={form.getFieldValue('occupation')}
                  prefixLabel="cbo"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="salary_range_id" label="Faixa salárial">
                <FormSelect
                  onSearch={(value: string) => searchSalaryRange(value)}
                  defaultValue={form.getFieldValue('salary_range')?.description}
                  items={salaryRangeList}
                  field={form.getFieldValue('salary_range')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="working_hour_id" label="Carga horaria">
                <FormSelect
                  onSearch={(value: string) => searchWorkingHours(value)}
                  defaultValue={form.getFieldValue('working_hour')?.description}
                  items={workingHoursList}
                  field={form.getFieldValue('working_hour')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Cancelar
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
