import { Col, Row } from 'antd';
import LayoutFull from '../../components/LayoutFull';

export function NotAvailable() {
  return (
    <LayoutFull headerTitle="Em desenvolvimento">
      <Row gutter={8}>
        <Col span={5}>
          <h1>Módulo em desenvolvimento</h1>
        </Col>
      </Row>
    </LayoutFull>
  );
}
