import { Select, Spin, SelectProps } from 'antd';

type FormSelectProps = SelectProps<any> & {
  field?: any;
  sufixLabel?: string;
  prefixLabel?: string;
  itemIndex?: string;
  items: string[];
  onSearch: (search: string) => void;
  onChange?: (item: any) => void;
};

export default function FormSelect({
  field,
  sufixLabel,
  prefixLabel,
  itemIndex,
  items,
  onSearch,
  onChange,
  ...rest
}: FormSelectProps) {
  const { Option } = Select;
  return (
    <Select
      labelInValue={false}
      placeholder="Digite para buscar..."
      notFoundContent={!items ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
      filterOption={false}
      onSearch={onSearch}
      onChange={onChange}
      style={{ width: '100%' }}
      showSearch
      allowClear
      {...rest}>
      {field?.id && (
        <Option key={field?.id} value={field?.id}>
          {prefixLabel && `${field[prefixLabel]} - `}
          {sufixLabel ? field[sufixLabel] : field?.description}
        </Option>
      )}
      {items?.map(
        (item: any) => {
          const itemField = itemIndex ? item[itemIndex] : item;
          if (itemField?.id !== field?.id) {
            return (
              <Option key={itemField?.id} value={itemField?.id} title={sufixLabel ? itemField[sufixLabel] : itemField?.description}>
                {prefixLabel && `${itemField[prefixLabel]} - `}
                {sufixLabel ? itemField[sufixLabel] : itemField?.description}
              </Option>
            )
          }
        }

      )}
    </Select>
  );
}
