import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AccountPlan } from '../screens/AccountPlan';
import { AccountPlanDefault } from '../screens/AccountPlanDefault';
import { CBO } from '../screens/CBO';
import { CFOPSped } from '../screens/CFOPSped';
import { CFOPXml } from '../screens/CFOPXml';
import { CandidatePersonal } from '../screens/CandidatePersonal';
import { Company } from '../screens/Company';
import { CostCenter } from '../screens/CostCenter';
import { DocumentType } from '../screens/DocumentType';
import { Event } from '../screens/Event';
import { EventDefault } from '../screens/EventDefault';
import { GroupAccountPlan } from '../screens/GroupAccountPlan';
import { GroupPermission } from '../screens/GroupPermission';
import { HistoricDefault } from '../screens/HistoricDefault';
import { Historic } from '../screens/Historics';
import { InterestsAreas } from '../screens/InterestsAreas';
import { Jobs } from '../screens/Jobs';
import { Module } from '../screens/Module';
import { NotAvailable } from '../screens/NotAvailable';
import { NotaFiscal } from '../screens/NotaFiscal';
import { NotaFiscalDetails } from '../screens/NotaFiscal/details';
import { NotaFiscalImport } from '../screens/NotaFiscalImport';
import { NotaFiscalImportFiles } from '../screens/NotaFiscalImportFile';
import { Occupation } from '../screens/Occupation';
import { Organization } from '../screens/Organization';
import { Partner } from '../screens/Partner';
import { PaymentType } from '../screens/PaymentType';
import { People } from '../screens/People';
import { Permission } from '../screens/Permission';
import { Product } from '../screens/Product';
import { ReferencialPlan } from '../screens/ReferencialPlan';
import { ReportDRE } from '../screens/Report/ReportDRE';
import { ReportRevenueBB } from '../screens/Report/ReportRevenueBB';
import { ReportRevenueDefault } from '../screens/Report/ReportRevenueDefault';
import { Role } from '../screens/Role';
import { SubModule } from '../screens/SubModule';
import { TypePermission } from '../screens/TypePermission';
import { User } from '../screens/User';
import { Accountants } from '../screens/Accountants';
import { ReportCompareXmlSped } from '../screens/Report/ReportCompareXmlSped';
import { ReportBalancet } from '../screens/Report/ReportBalancet';
import { ConfirmEmail } from '../screens/ConfirmEmail';
import { ReportImportExportMoviments } from '../screens/Report/ReportImportExportMoviments';
import { CompanyTax } from '../screens/CompanyTax';
import { ReportBalance } from '../screens/Report/ReportBalance';
import { Service } from '../screens/Service';
import { Cnae } from '../screens/Cnae';
import { CompanyCnae } from '../screens/CompanyCnae';
import { CompanyService } from '../screens/CompanyService';
import { ServiceNotaFiscal } from '../screens/ServiceNotaFiscal';
import { ReportCashMoviments } from '../screens/Report/ReportCashMoviment';

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Event} />
        <Route exact path="/profile" component={NotAvailable} />

        <Route exact path="/confirm-email/:hash" component={ConfirmEmail} />
        <Route exact path="/registers/nota-fiscal-import" component={NotaFiscalImport} />
        <Route
          exact
          path="/registers/nota-fiscal-import-file/:nota_fiscal_import_id"
          component={NotaFiscalImportFiles}
        />

        <Route exact path="/registers/costcenters" component={CostCenter} />
        <Route exact path="/registers/peoples" component={People} />
        <Route exact path="/registers/products" component={Product} />
        <Route exact path="/registers/cbo" component={CBO} />
        <Route exact path="/registers/partners" component={Partner} />
        <Route exact path="/registers/interestsareas" component={InterestsAreas} />
        <Route exact path="/registers/organization" component={Organization} />
        <Route exact path="/registers/candidate-personal" component={CandidatePersonal} />
        <Route exact path="/registers/occupation" component={Occupation} />
        <Route exact path="/registers/accountants" component={Accountants} />

        <Route exact path="/accountancy/events" component={Event} />
        <Route exact path="/accountancy/eventsdefault" component={EventDefault} />
        <Route exact path="/accountancy/groupsaccountplan" component={GroupAccountPlan} />
        <Route exact path="/accountancy/accountplandefault" component={AccountPlanDefault} />
        <Route exact path="/accountancy/accountplan" component={AccountPlan} />
        <Route exact path="/accountancy/referencial-plan/:segmentId" component={ReferencialPlan} />
        <Route exact path="/accountancy/historics" component={Historic} />

        <Route exact path="/manager/roles" component={Role} />
        <Route exact path="/manager/paymenttype" component={PaymentType} />
        <Route exact path="/manager/documenttype" component={DocumentType} />
        <Route exact path="/manager/users" component={User} />
        <Route exact path="/manager/companies" component={Company} />
        <Route exact path="/manager/historicsdefault" component={HistoricDefault} />
        <Route exact path="/manager/modules" component={Module} />
        <Route exact path="/manager/submodules" component={SubModule} />
        <Route exact path="/manager/typepermissions" component={TypePermission} />
        <Route exact path="/manager/grouppermissions" component={GroupPermission} />
        <Route exact path="/manager/permissions" component={Permission} />
        <Route exact path="/manager/services" component={Service} />
        <Route exact path="/manager/cnaes" component={Cnae} />

        <Route exact path="/pessoalweb/jobs" component={Jobs} />

        <Route exact path="/fiscal/nfes" component={NotaFiscal} />
        <Route exact path="/fiscal/nfes/:id" component={NotaFiscalDetails} />
        <Route exact path="/fiscal/company-taxes" component={CompanyTax} />
        <Route exact path="/fiscal/company-cnaes" component={CompanyCnae} />
        <Route exact path="/fiscal/company-services" component={CompanyService} />
        <Route exact path="/fiscal/nfse" component={ServiceNotaFiscal} />

        <Route exact path="/utils/cfopxml" component={CFOPXml} />
        <Route exact path="/utils/cfopsped" component={CFOPSped} />

        <Route exact path="/reports/revenuedefault" component={ReportRevenueDefault} />
        <Route exact path="/reports/revenuebb" component={ReportRevenueBB} />
        <Route exact path="/reports/dre" component={ReportDRE} />
        <Route exact path="/reports/compare-xml-sped" component={ReportCompareXmlSped} />
        <Route exact path="/reports/balancete" component={ReportBalancet} />
        <Route exact path="/reports/balance" component={ReportBalance} />
        <Route exact path="/reports/import-export-moviments" component={ReportImportExportMoviments} />
        <Route exact path="/reports/report-cash-moviments" component={ReportCashMoviments} />
      </Switch>
    </BrowserRouter>
  );
}
