import { DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu, Modal, Table, message } from 'antd';
import { HTMLProps, useEffect, useState } from 'react';
import Logo from '../../assets/images/logo-full.png';
import { useAuth } from '../../contexts/auth.context';
import api from '../../services/api.service';
import { getNameInitials } from '../../utils/getNameInitials';
import Footer from '../Footer';
import MenuSidebar from '../MenuSidebar';

interface LayoutFullProps extends HTMLProps<HTMLElement> {
  children: any;
  headerTitle?: string;
  headerIcon?: any;
}

export default function LayoutFull({
  children,
  headerTitle,
  headerIcon,
  ...rest
}: LayoutFullProps) {
  const { doLogout, setCompany, user } = useAuth();

  const { Header, Sider, Content } = Layout;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const nameInitals: string = getNameInitials();

  const [visibleModalChangeCompany, setVisibleModalChangeCompany] = useState(false);
  const [dataModalChangeCompany, setDataModalChangeCompany] = useState([]);
  const [dataLocalStorage, setDataLocalStorage] = useState<any>({});

  const getCompanies = async () => {
    try {
      const { data } = await api.get('organization');
      const dataParsed = data.map((item: any) => {
        if (item.company_id === dataLocalStorage.company_default_id) {
          item.disabled = true;
        }
        return item;
      });

      setDataModalChangeCompany(dataParsed);
      setVisibleModalChangeCompany(true);
    } catch (err: any) {
      message.error(err?.message);
    }
  };

  const changeCompany = async (companyId: number, fantasyName: string) => {
    try {
      const company = {
        ...dataLocalStorage,
        company_default: { id: companyId, fantasy_name: fantasyName },
        company_default_id: companyId,
        companyId,
      };

      setDataLocalStorage(company);
      await localStorage.setItem('@AtivosDigitalWeb:user', JSON.stringify(company));
      await api.put(`user/${dataLocalStorage.id}`, {
        company_default_id: companyId,
      });

      setCompany(companyId);
      setVisibleModalChangeCompany(false);
    } catch (err: any) {
      message.error(err?.message);
    }
  };

  const getDataLocalStorage = () => {
    const originalData = localStorage.getItem('@AtivosDigitalWeb:user');

    if (typeof originalData !== 'string') {
      return;
    }

    const localStorageData = JSON.parse(localStorage.getItem('@AtivosDigitalWeb:user') || '');
    setDataLocalStorage(localStorageData);
  };

  const handlerResendConfirmationEmail = async (email: string) => {
    try {
      await api.put(`confirm-email/resend/${email}`);
      message.success(`Email de confirmação enviado para ${email}`);
    } catch (err: any) {
      message.success(err.message);
    }
  };

  useEffect(() => {
    getDataLocalStorage();
  }, []);

  return (
    <Layout {...rest}>
      <Sider
        trigger={null}
        collapsible
        collapsed={menuCollapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}>
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: 12,
            backgroundColor: '#fff',
          }}>
          <img src={Logo} width="80%" alt="Ilustração" />
        </div>
        <MenuSidebar />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: menuCollapsed ? 80 : 200 }}>
        <div className="header-container">
          {!dataLocalStorage.confirm_mail && (
            <div className="alert-resend-email">
              <p>
                Seu email ainda não foi confirmado{' '}
                <button onClick={(e) => handlerResendConfirmationEmail(dataLocalStorage.email)}>
                  Clique aqui
                </button>{' '}
                para reenviar o link de confirmação
              </p>
            </div>
          )}
          <Header className="site-layout-background">
            {menuCollapsed ? (
              <MenuUnfoldOutlined
                className="trigger"
                onClick={() => setMenuCollapsed(!menuCollapsed)}
              />
            ) : (
              <MenuFoldOutlined
                className="trigger"
                onClick={() => setMenuCollapsed(!menuCollapsed)}
              />
            )}
            <h2 style={{ padding: 0, margin: 0 }}>{headerTitle}</h2>
            <div
              style={{
                position: 'fixed',
                right: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <small style={{ lineHeight: '15px' }}>Empresa:</small>
                <strong style={{ lineHeight: '15px' }}>{user?.company_default?.fantasy_name}</strong>
              </div>
              <Dropdown
                overlay={() => (
                  <Menu>
                    <Menu.Item danger onClick={() => getCompanies()}>
                      Alterar empresa
                    </Menu.Item>
                    <Menu.Item danger onClick={() => doLogout()}>
                      Sair
                    </Menu.Item>
                  </Menu>
                )}>
                <Button type="link">
                  <Avatar style={{ backgroundColor: 'red', verticalAlign: 'middle' }} size="large">
                    {nameInitals}
                  </Avatar>
                  <strong
                    className="ant-dropdown-link"
                    style={{
                      marginLeft: 12,
                      color: '#000',
                    }}>
                    Minha Conta <DownOutlined />
                  </strong>
                </Button>
              </Dropdown>
            </div>
          </Header>
        </div>
        <Content
          style={{
            marginTop: dataLocalStorage.confirm_mail ? 60 : 90,
            padding: 24,
          }}>
          {children}
          <Modal
            style={{ top: 25, maxHeight: '90vh' }}
            title="Selecione a empresa"
            visible={visibleModalChangeCompany}
            onCancel={() => setVisibleModalChangeCompany(false)}
            footer={
              <Button type="default" onClick={() => setVisibleModalChangeCompany(false)}>
                Fechar
              </Button>
            }>
            <Table
              dataSource={dataModalChangeCompany}
              pagination={false}
              style={{ overflow: 'auto', height: '70vh' }}
              columns={[
                {
                  title: 'Nome da empresa',
                  dataIndex: 'fantasy_name',
                  width: '80%',
                  render: (value, record: any) => (
                    <div>
                      <p>{record?.company?.fantasy_name}</p>
                      {record?.disabled && 'Empresa atual'}
                    </div>
                  ),
                },
                {
                  title: 'Ações',
                  render: (value, record: any) => (
                    <Button
                      disabled={record.disabled}
                      type="primary"
                      size="small"
                      onClick={() => changeCompany(record.company_id, record.company.fantasy_name)}>
                      Selecionar
                    </Button>
                  ),
                  width: '20%',
                },
              ]}
            />
          </Modal>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}
