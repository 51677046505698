import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Select, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

type FormFilter = {
  created_at: string[] | undefined;
  process_number: string;
  status: string;
  company_id: number;
};

const Status: any = {
  success: {
    color: 'green',
    label: 'Sucesso',
  },
  error: {
    color: 'red',
    label: 'Erro',
  },
};

export function NotaFiscalImportFiles() {
  const { nota_fiscal_import_id } = useParams<any>();

  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);
  const { Option } = Select;

  const columns: any[] = [
    {
      title: 'Arquivo',
      dataIndex: 'filename',
      key: 'filename',
      ellipsis: {
        showTitle: false,
      },
      render: (error: string) => (
        <Tooltip placement="topLeft" title={error}>
          {error}
        </Tooltip>
      ),
    },
    {
      title: 'Erro',
      dataIndex: 'error',
      key: 'error',
      ellipsis: {
        showTitle: false,
      },
      render: (error: string) => (
        <Tooltip placement="topLeft" title={error}>
          {error}
        </Tooltip>
      ),
    },
    {
      title: 'Criado em',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: (a: any, b: any) => a.created_at.length - b.created_at.length,
      render: (value: string, record: any) =>
        value && moment(record.created_at).format('DD/MM/YYYY - HH:mm:ss'),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (a: any, b: any) => a.status.length - b.status.length,
      render: (text: string) => <Tag color={Status[text].color}>{Status[text].label}</Tag>,
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        ...filters,
        ...{ nota_fiscal_import_id },
        ...{ page: currentPage },
      };
      const { data } = await api.get('nota-fiscal-import-file', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters, nota_fiscal_import_id]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.created_at) {
      const [date_start, date_end] = values.created_at;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      created_at: periodFilder || undefined,
      process_number: values.process_number || undefined,
      status: values.status || undefined,
      company_id: values.company_id || undefined,
    });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Relação de arquivos importados">
      <Form onFinish={handlerFilter} onFinishFailed={() => {}} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={5}>
            <Form.Item name="status" label="Status">
              <Select placeholder="Status" allowClear>
                <Option value="success">Sucesso</Option>
                <Option value="error">Erro</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
    </LayoutFull>
  );
}
