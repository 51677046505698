import { Col, Collapse, Form, Input, message, Row, Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';
import { dateTimeFormatBr } from '../../utils/dateTimeFormatBr';

const OperationTarget: any = {
  1: '1 - Operação interna',
};
const BuyerPresence: any = {
  1: '1 - Operação presencial',
};
const OperationType: any = {
  1: '1 - Saída',
};
const PaymentType: any = {
  1: '1 - A Prazo',
};
const FinalyType: any = {
  1: '1 - Normal',
};
const FinalConsumerType: any = {
  0: '0 - Não',
  1: '1 - Sim',
};
const EmissionType: any = {
  1: '1 - Normal',
};
const PrintType: any = {
  4: '4 - DANFE NFC-e',
};
const Process: any = {
  0: '0 - Com aplicativo do contribuinte',
};

export function NotaFiscalDetails() {
  const { id } = useParams<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const [nfe, setNFe] = useState<any>({} as any);

  const { Panel } = Collapse;

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`nfe/${id}`);
      setNFe(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Notas Fiscal Detalhes">
      {loading && <p>Carregando...</p>}
      <Tabs defaultActiveKey="nfe" type="card">
        <Tabs.TabPane tab="Nota Fiscal" key="nfe">
          <Form layout="vertical">
            <Row gutter={12}>
              <Col span={24}>
                <strong>Dados da NF</strong>
                <hr />
              </Col>
              <Col span={4}>
                <Form.Item label="Modelo">
                  <Input value={nfe.model} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Série">
                  <Input value={nfe.serie} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Número">
                  <Input value={nfe.number} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Data de Emissão">
                  <Input value={dateTimeFormatBr(nfe.emission_at, 'DD/MM/YYYY HH:MM:SS')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Data saída/Entrada">
                  <Input
                    value={`${dateTimeFormatBr(nfe.date_in)} ${
                      dateTimeFormatBr(nfe.date_out) || ''
                    }`}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Valor total">
                  <Input value={nfe.amount} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <strong>Destinatário</strong>
                <hr />
              </Col>
              <Col span={8}>
                <Form.Item label="Destino da operação">
                  <Input value={OperationTarget[nfe.operation_target]} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Consumidor final">
                  <Input value={FinalConsumerType[nfe.final_consumer]} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Presença do comprador">
                  <Input value={BuyerPresence[nfe.buyer_presence]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <strong>Emissão</strong>
                <hr />
              </Col>
              <Col span={6}>
                <Form.Item label="Processo">
                  <Input value={Process[nfe.process]} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Versão do processo">
                  <Input value={nfe.process_version} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Tipo de emissão">
                  <Input value={EmissionType[nfe.type_emission]} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Finalidade">
                  <Input value={FinalyType[nfe.finality]} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Natureza da operação">
                  <Input value={nfe.finality_operation} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Tipo de operação">
                  <Input value={OperationType[nfe.type]} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Forma de pagamento">
                  <Input value={PaymentType[nfe.type_payment]} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Digest value da NF-e">
                  <Input value={nfe.digest_value} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <strong>Situação atual: </strong>
                <hr />
              </Col>
              <Col span={8}>
                <Form.Item label="Eventos da NF">
                  <Input value={nfe.event_description} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Protocolo">
                  <Input value={nfe.event_protocol} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Data/Hora">
                  <Input value={nfe.event_datetime} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Emitente" key="issuer">
          <Form layout="vertical">
            <Row gutter={12}>
              <Col span={24}>
                <strong>Dados do Emitente </strong>
                <hr />
              </Col>
              <Col span={12}>
                <Form.Item label="Nome / Razão Social:">
                  <Input value={nfe?.nota_fiscal_receiver?.social_name} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Nome Fantasia:">
                  <Input value={nfe?.nota_fiscal_receiver?.fantasy_name} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="CNPJ:">
                  <Input value={nfe?.nota_fiscal_receiver?.document} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Endereço:">
                  <Input value={nfe?.nota_fiscal_receiver?.address} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Bairro / Distrito:">
                  <Input value={nfe?.nota_fiscal_receiver?.district} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="CEP:">
                  <Input value={nfe?.nota_fiscal_receiver?.cep} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Município:">
                  <Input value={nfe?.nota_fiscal_receiver?.city} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Telefone:">
                  <Input value={nfe?.nota_fiscal_receiver?.telphone} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="UF:">
                  <Input value={nfe?.nota_fiscal_receiver?.uf} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="País:">
                  <Input value={nfe?.nota_fiscal_receiver?.country} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Inscrição Estadual:">
                  <Input value={nfe?.nota_fiscal_receiver?.inscricao_estadual} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Inscrição Municipal:">
                  <Input value={nfe?.nota_fiscal_receiver?.inscricao_municipal} />
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                                <Form.Item label="CNAE Fiscal:">
                                    <Input value={nfe.type_print}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Código de Regime Tributário:">
                                    <Input value={nfe.type_print}/>
                                </Form.Item>
                            </Col> */}
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Destinatário" key="people">
          <Form layout="vertical">
            <Row gutter={12}>
              <Col span={24}>
                <strong>Dados do Destinatário </strong>
                <hr />
              </Col>
              <Col span={12}>
                <Form.Item label="Nome / Razão Social:">
                  <Input value={nfe?.nota_fiscal_receiver?.social_name} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Nome Fantasia:">
                  <Input value={nfe?.nota_fiscal_receiver?.fantasy_name} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="CNPJ:">
                  <Input value={nfe?.nota_fiscal_receiver?.document} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Endereço:">
                  <Input value={nfe?.nota_fiscal_receiver?.address} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Bairro / Distrito:">
                  <Input value={nfe?.nota_fiscal_receiver?.district} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="CEP:">
                  <Input value={nfe?.nota_fiscal_receiver?.cep} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Município:">
                  <Input value={nfe?.nota_fiscal_receiver?.city} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Telefone:">
                  <Input value={nfe?.nota_fiscal_receiver?.telphone} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="UF:">
                  <Input value={nfe?.nota_fiscal_receiver?.uf} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="País:">
                  <Input value={nfe?.nota_fiscal_receiver?.country} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Inscrição Estadual:">
                  <Input value={nfe?.nota_fiscal_receiver?.inscricao_estadual} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Inscrição Municipal:">
                  <Input value={nfe?.nota_fiscal_receiver?.inscricao_municipal} />
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                                <Form.Item label="CNAE Fiscal:">
                                    <Input value={nfe.type_print}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Código de Regime Tributário:">
                                    <Input value={nfe.type_print}/>
                                </Form.Item>
                            </Col> */}
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Produtos/Serviços" key="items">
          <Form layout="vertical">
            <Row gutter={12}>
              <Col span={24}>
                <strong>Dados do Produtos </strong>
                <hr />
              </Col>
            </Row>
            {(nfe?.notas_fiscais_products || []).map((item: any) => (
              <Row gutter={12}>
                <Col span={3}>
                  <Form.Item label="Código:">
                    <Input value={item?.code} />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="Descrição:">
                    <Input value={item?.description} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="Qtd:">
                    <Input value={item?.commercial_quantity} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="UN Comercial:">
                    <Input value={item.comercial_unity} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Valor (R$):">
                    <Input value={item?.commercial_unit_price} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Collapse defaultActiveKey={['0']} ghost>
                    <Panel header="Mais detalhes do produto" key="1">
                      <Row gutter={12}>
                        <Col span={3}>
                          <Form.Item label="NCM:">
                            <Input value={item?.ncm} />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label="TIPI:">
                            <Input value={item?.tipi} />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label="CFOP:">
                            <Input value={item?.cfop} />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label="Desconto">
                            <Input value={item?.discount} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="Frete">
                            <Input value={item?.shipping_price} />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label="Seguro">
                            <Input value={item?.security_price} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label="EAN Comercial">
                            <Input value={item?.comercial_ean} />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item label="UN Comercial">
                            <Input value={item?.comercial_unity} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="Qtd Comercial">
                            <Input value={item?.commercial_quantity} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label="EAN Tributável">
                            <Input value={item?.tributation_ean} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="UN Tributável">
                            <Input value={item?.taxable_unit} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="Qtd Tributável">
                            <Input value={item?.taxable_quantity} />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item label="Valor un de comercialização">
                            <Input value={item?.commercial_unit_price} />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item label="Valor un de tributação">
                            <Input value={item?.taxable_unit_amount} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="CEST">
                            <Input value={item?.cest} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            ))}
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Totais" key="totals">
          Content 2
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transporte" key="fees">
          Content 2
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cobrança" key="charges">
          Content 2
        </Tabs.TabPane>
        <Tabs.TabPane tab="Inf. Adicionais" key="info">
          <Row gutter={12}>
            <Col span={24}>
              <strong>Informações adicionais: </strong>
              <hr />
            </Col>
            <Col span={6}>
              <Form.Item label="Formato de Impressão DANFE">
                <Input value={PrintType[nfe.type_print]} />
              </Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </LayoutFull>
  );
}
