export const formatDateToBr = (text: string, callback: (result: string) => void) => {
  if (!text) return;
  const textFormatted = text
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
  callback(textFormatted);

};

export const formatCurrency = (text: string, callback: (result: string) => void) => {
  if (!text) return;
  const textFormatted = text
    .toString()
    .replace(/[^0-9,]/g, '')
    .replace(/(,)(.*?)(\1)/g, ',');
  callback(textFormatted);
};

export const formatCurrencyToBr = (value: string | number) => {
  if (!value) return 0;
  if (typeof value === 'number') {
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
  }
  return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(value.replace(',', '.')))
}

export const formatCepMask = (text: string, callback: (result: string) => void) => {
  if (!text) return;
  const textFormatted = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
  callback(textFormatted);
};

export const formatCellMask = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
};

export const formatDocument = (text: string) => {
  if (!text) return;
  if (text.length > 11) {
    return text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  return text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}
