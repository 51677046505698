import { message } from 'antd';
import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_URL_DEV
      : process.env.REACT_APP_URL_PRD,
  timeout: 990000,
  timeoutErrorMessage: 'Servidor indisponível. Verifique sua conexão',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('@AtivosDigitalWeb:token');
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}` || '',
    };
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('@AtivosDigitalWeb:user');
        localStorage.removeItem('@AtivosDigitalWeb:token');
      }
    } else if (error?.response?.data?.message) {
      message.error(error?.response?.data?.message);
    }
    return Promise.reject(error);
  },
);

export default api;
