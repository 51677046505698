import { Button, Col, Row } from 'antd';

type CustomPaginationProps = {
  onPrevious: () => void;
  onNext: () => void;
  currentPage: number;
  dataTableLength: boolean;
};
export default function CustomPagination({
  onPrevious,
  onNext,
  currentPage,
  dataTableLength,
}: CustomPaginationProps) {
  return (
    <Row>
      <Col span={12} />
      <Col
        span={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: 12,
        }}>
        <Button disabled={currentPage === 1} onClick={onPrevious}>
          Anterior
        </Button>
        <strong style={{ padding: '14px' }}>
          Página:
          {currentPage}
        </strong>
        <Button disabled={!dataTableLength} onClick={onNext}>
          Próximo
        </Button>
      </Col>
    </Row>
  );
}
