import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Upload,
} from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import { EventTypeOptions } from '../../models/EventType';
import api from '../../services/api.service';
import { formatCurrency, formatCurrencyToBr, formatDateToBr } from '../../utils/masks';
import numberFormatCurrency from '../../utils/numberFormatCurrency';
import FormSelect from '../../components/FormSelect';
import ImageXmlEvent from '../../assets/images/xml-file.png';
import ImageSpedEvent from '../../assets/images/sped-file.png';
import ImageManualEvent from '../../assets/images/manual-file.png';
import { useAuth } from '../../contexts/auth.context';

type FormFilter = {
  code: number;
  people_id: number;
  account_plan_id: number;
  date: string[] | undefined;
  conciliated: number;
  document: string;
  historic_id: number;
  amount: number;
  product_id: number;
  updated: boolean;
  type: number;
  origin: number;
  nfe_key: number;
  cfop: number;
};

export function Event() {
  const SIZE_IN_MEGABYTES = 1024 * 1024;

  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [accountPlanList, setAccountPlanList] = useState<any[]>([]);
  const [eventType, setEventType] = useState<any>(3);
  const [eventAccountList, setEventAccountList] = useState<any>([]);
  const [historicList, setHistoricList] = useState<any>([]);
  const [eventDefaultList, setEventDefaultList] = useState<any>([]);
  const [paymentTypeList, setPaymentTypeList] = useState<any>([]);
  const [documentTypeList, setDocumentTypeList] = useState<any>([]);
  const [costCenterList, setCostCenterList] = useState<any>([]);
  const [peopleList, setPeopleList] = useState<any>([]);
  const { user } = useAuth();

  const [modalPhotoVisible, setModalPhotoVisible] = useState<boolean>(false);
  const [modalPhotoUrl, setModalPhotoUrl] = useState<string>('');

  const [previewImage, setPreviewImage] = useState({
    visible: false,
    url: '',
    modalVisible: false,
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileImage, setFileImage] = useState<any>();

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();
  const [formFilter] = Form.useForm();
  const [formAccounts] = Form.useForm();

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const FileTypeImages: any = {
    1: ImageXmlEvent,
    2: ImageSpedEvent,
    3: ImageManualEvent
  }

  const columns: any[] = [
    {
      title: 'Código',
      key: 'code',
      dataIndex: 'code',
      sorter: (a: any, b: any) => a.code.length - b.code.length
    },
    {
      title: 'Imagem',
      key: 'image',
      dataIndex: 'image',
      render: (value: string, record: any) => {
        if (record.origin !== 3) {
          return <img src={FileTypeImages[record.origin]} alt="Ilustração" height="40" />;
        }
        return (
          <img
            src={process.env.REACT_APP_IMAGES_URL + record.image}
            alt="Ilustração"
            height="60"
            onClick={() => handlerImagePreview(record.image)}
          />
        );
      },
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
      sorter: (a: any, b: any) => a.type.length - b.type.length,
      render: (value: string, record: any) => (
        <Tag color="blue">{EventTypeOptions[value]?.label}</Tag>
      ),
    },
    {
      title: 'Histórico complementar',
      key: 'historic_complement',
      dataIndex: 'historic_complement',
      sorter: (a: any, b: any) => a.historic_complement.length - b.historic_complement.length,
    },
    {
      title: 'Data',
      key: 'date',
      dataIndex: 'date',
      sorter: (a: any, b: any) => a.date.length - b.date.length,
      render: (value: string, record: any) => value && moment.utc(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Valor',
      key: 'amount',
      dataIndex: 'amount',
      sorter: (a: any, b: any) => a.amount.length - b.amount.length,
      render: (value: string, record: any) => numberFormatCurrency(record.amount),
    },
    {
      title: 'Conciliado',
      dataIndex: 'conciliated',
      key: 'conciliated',
      align: 'center',
      render: (value: boolean, record: any) => (
        <Switch
          size="small"
          defaultChecked={value}
          onClick={(value) => handleToggleConciliate(record.id, value)}
        />
      ),
      sorter: (a: any, b: any) => a?.conciliate - b?.conciliate,
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record.id)}>
            <EditOutlined /> Editar
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esse item?"
            onConfirm={() => remove(record.id)}
            okText="Sim"
            cancelText="Não">
            <Button type="link" style={{ color: 'red' }}>
              <DeleteOutlined /> Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage, company_id: user?.company_default_id } };
      const { data } = await api.get('event', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilder;
    if (values.date) {
      const [date_start, date_end] = values.date;
      periodFilder = [
        date_start && moment(date_start).format('YYYY-MM-DD'),
        date_end && moment(date_end).format('YYYY-MM-DD'),
      ];
    }
    setCurrentPage(1);
    setFilters({
      date: periodFilder || undefined,
      conciliated: values?.conciliated,
      document: values?.document?.trim() || undefined,
      historic_id: values?.historic_id || undefined,
      amount: values?.amount?.replace(',', '.') || undefined,
      product_id: values?.product_id || undefined,
      updated: values?.updated || undefined,
      type: values?.type || undefined,
      origin: values?.origin || undefined,
      code: values?.code || undefined,
      people_id: values?.people_id || undefined,
      account_plan_id: values?.account_plan_id || undefined,
      nfe_key: values?.nfe_key || undefined,
      cfop: values?.cfop || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(
    async (id: number) => {
      formAccounts.resetFields();
      setEventAccountList([]);
      searchCostCenter('');
      searchPaymentType('');
      searchDocumentType('');
      searchHistoric('');
      searchAccountPlan('');

      const { data } = await api.get(`event/${id}`);

      data.date = moment(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      data.amount = data.amount.replace('.', ',');

      form.setFieldsValue(data);

      data?.event_accounts?.forEach((item: any) => {
        if (item.type === 'debit') {
          if (![2, 3, 4].includes(item.type)) {
            setEventAccountList((old: any) => [item, ...old]);
          }
        } else if (![1, 3, 2].includes(item.type)) {
          setEventAccountList((old: any) => [item, ...old]);
        }
      });

      if (data.image) {
        const image: UploadFile = {
          uid: '1',
          name: data.image,
          status: 'done',
          url: `${process.env.REACT_APP_IMAGES_URL}${data.image}`,
        };
        setFileList([image]);
      }

      setShowDrawer(true);
      setDrawerTitle('Editar cadastro');
      setErrors([]);
    },
    [form],
  );

  const handleToggleConciliate = async (id: string, conciliated: boolean) => {
    try {
      await api.put(`event/${id}`, { conciliated });
      message.success('Registro atualizado com sucesso');
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const remove = useCallback(
    async (id: string) => {
      try {
        await api.delete(`event/${id}`);
        message.success('Registro excluido com sucesso');
        getData();
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [getData],
  );

  const openDrawer = () => {
    setErrors([]);
    form.resetFields();
    formAccounts.resetFields();
    setEventAccountList([]);

    searchPaymentType('');
    searchDocumentType('');

    setShowDrawer(true);
    setDrawerTitle('Novo cadastro');
  };

  const saveOrCreate = async (values: any) => {

    const eventAccounts: any[] = [];

    if (eventAccountList.length) {
      for (const account of eventAccountList) {
        values.amount = values.amount.replace(',', '.');

        const item: any = {};
        const amount = account.type === 'debit' ? Math.abs(account.amount) * -1 : account.amount;

        if (account.id) {
          item.id = account.id;
          item.event_id = account.event_id;
        }

        item.type = account.type;
        item.account_plan_id = account.account_plan_id;
        item.historic_id = account.historic_id;
        item.amount = amount;

        eventAccounts.push(item);
      }
    }

    const data = { ...values, event_accounts: eventAccounts };
    data.date = moment(data.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.amount = data.amount.replace(',', '.');
    data.conciliated = values.conciliated ? 1 : 0;

    const formData = new FormData();
    data.event_default_id && formData.append('event_default_id', data.event_default_id);
    data.historic_id && formData.append('historic_id', data.historic_id);
    data.cost_center_id && formData.append('cost_center_id', data.cost_center_id);
    data.historic_complement && formData.append('historic_complement', data.historic_complement);
    data.document && formData.append('document', data.document);
    data.image && formData.append('image', data.image);

    formData.append('date', moment(data.date).format('YYYY-MM-DD'));
    formData.append('amount', data.amount);
    formData.append('type', data.type);
    formData.append('payment_type_id', data.payment_type_id);
    formData.append('document_type_id', data.document_type_id);
    formData.append('conciliated', data.conciliated);
    formData.append('origin', data.origin);
    formData.append('company_id', `${user?.company_default_id}`);

    if (fileImage) {
      formData.append('photo', fileImage);
    }

    if (data.event_accounts) {
      formData.append('event_accounts', JSON.stringify(data.event_accounts));
    }

    setLoading(true);
    if (data.id) {
      try {
        await api.put(`event/${data.id}`, formData);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await api.post('event', formData);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchAccountPlan = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('account-plan', {
        params: { page: 1, per_page: 50, description: search, type: 'a', company_id: user?.company_default_id },
      });
      setAccountPlanList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchHistoric = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('historic', {
        params: { page: 1, per_page: 100, description: search, company_id: user?.company_default_id },
      });
      setHistoricList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchEventDefault = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('event-default', {
        params: { page: 1, per_page: 100, description: search, company_id: user?.company_default_id },
      });
      setEventDefaultList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchPaymentType = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('payment-type', {
        params: { page: 1, per_page: 100, description: search },
      });
      setPaymentTypeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchDocumentType = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('document-type', {
        params: { page: 1, per_page: 100, description: search },
      });
      setDocumentTypeList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchCostCenter = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('cost-center', {
        params: { description: search, company_id: user?.company_default_id },
      });
      setCostCenterList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchPeople = async (search: string) => {
    try {
      setLoading(true);
      const { data } = await api.get('people', {
        params: { name: search, company_id: user?.company_default_id },
      });
      setPeopleList(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleClosePreviewImage = () => setPreviewImage({ ...previewImage, modalVisible: false });

  const handleOpenPreviewImage = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage({
      ...previewImage,
      modalVisible: true,
      url: file.url || (file.preview as string),
    });
  };

  const handleChange: UploadProps['onChange'] = ({ fileList }) => {
    const file = fileList[0];
    if (file.size && file.type) {
      const typesAvailabled = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type);
      const size = file.size / SIZE_IN_MEGABYTES <= 1;

      if (!typesAvailabled) {
        notification.error({
          message: 'Envie somente imagens do tipo: PNG, JPG ou JPEG',
        });
        return;
      }

      if (!size) {
        notification.error({
          message: 'O arquivo enviado é maior que 1MB',
        });
        return;
      }
      setFileList([file]);
    }
  };

  const handleUpload = (file: RcFile): boolean => {
    setFileImage(file);
    return false;
  };

  const handlerAddEventAccount = (values: any) => {
    values.amount = values?.amount?.replace(',', '.');
    setEventAccountList((old: any) => [...old, values]);
  };

  const handleSelectEventDefault = async (eventId: string) => {
    try {
      setLoading(true);

      setEventAccountList([]);
      searchCostCenter('');
      searchPaymentType('');
      searchDocumentType('');
      searchHistoric('');
      searchAccountPlan('');

      const { data } = await api.get(`event-default/${eventId}`);
      form.setFieldsValue({
        type: data.type,
        company_id: data.company_id,
        people_id: data.people_id,
        historic_id: data.historic_id,
        historic_complement: data.historic_complement,
        event_default_id: data.id,
        pagament_type_id: data.pagament_type_id,
        document_type_id: data.document_type_id,
        lock_edit: data.lock_edit
      });

      data?.event_default_accounts?.forEach((item: any) => {
        if (item.type === 'debit') {
          form.setFieldsValue({ debit_account_id: item.account_plan_id });
          if (![2, 3, 4].includes(item.type)) {
            setEventAccountList((old: any) => [item, ...old]);
          }
        } else {
          form.setFieldsValue({ credit_account_id: item.account_plan_id });
          if (![1, 3, 2].includes(item.type)) {
            setEventAccountList((old: any) => [item, ...old]);
          }
        }
      });

      setEventType(data.type);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerDeleteEventAccountList = (item: any) => {
    const data = eventAccountList.filter((element: any) => element !== item);
    setEventAccountList(data);
  };

  const handlerImagePreview = (imageUrl: string) => {
    setModalPhotoVisible(true);
    setModalPhotoUrl(process.env.REACT_APP_IMAGES_URL + imageUrl);
  };

  const handleChangeEventAmount = (value: any) => {
    formatCurrency(value, (result) =>
      form.setFieldsValue({ amount: result })
    )

    if (form.getFieldValue('type') === 3) {
      const amount = form.getFieldValue('amount')
      const accountsTemp: any[] = []
      eventAccountList.forEach((item: any) => {
        accountsTemp.push({ ...item, amount });
      });
      setEventAccountList(accountsTemp);
    }
  }

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Lançamentos">
      <Form
        onFinish={handlerFilter}
        autoComplete="off"
        layout="vertical"
        form={formFilter}>
        <Row gutter={15}>
          <Col span={2}>
            <Form.Item name="code" label="Código">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="account_plan_id" label="Conta contábil">
              <FormSelect
                onSearch={(value: string) => searchAccountPlan(value)}
                items={accountPlanList}
                prefixLabel="account"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="people_id" label="Pessoa">
              <FormSelect
                onSearch={(value: string) => searchPeople(value)}
                items={peopleList}
                prefixLabel="code"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="type" label="Tipo de lançamento">
              <Select placeholder="Tipo de lançamento" allowClear>
                <Option value={1}>Um débito para vários créditos</Option>
                <Option value={2}>Um crédito para vários débitos</Option>
                <Option value={3}>Um débito para um crédito</Option>
                <Option value={4}>Vários débitos para vários créditos</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="document" label="Número do documento">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="conciliated" label="Conciliado">
              <Select placeholder="Conciliado" allowClear>
                <Option value={1}>Sim</Option>
                <Option value={0}>Não</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="updated" label="Editados">
              <Select allowClear>
                <Option value={1}>Somente editados</Option>
                <Option value="">Todos</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="origin" label="Origem">
              <Select placeholder="Origem do lançamento" allowClear>
                <Option key={1} value={1}>
                  Importação XML
                </Option>
                <Option key={2} value={2}>
                  Importação SPED
                </Option>
                <Option key={3} value={3}>
                  Manual
                </Option>
                <Option key={3} value={4}>
                  Importação por arquivo
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="date" label="Data">
              <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Data Inicial', 'Data Final']}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="amount" label="Valor">
              <Input
                allowClear
                onChange={(event) =>
                  formatCurrency(event.currentTarget.value, (result) =>
                    formFilter.setFieldsValue({ amount: result }),
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="nfe_key" label="Chave da Nota">
              <Input
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cfop" label="CFOP">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={openDrawer} style={{ marginTop: 30 }}>
              <PlusOutlined /> Novo
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title={drawerTitle}
        width="100%"
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
            type: 3,
            origin: 3,
            conciliated: 0,
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={15}>
              <Form.Item name="event_default_id" label="Lançamento padrão">
                <FormSelect
                  onSearch={(value: string) => searchEventDefault(value)}
                  defaultValue={form.getFieldValue('event_default')?.description}
                  items={eventDefaultList}
                  field={form.getFieldValue('event_default')}
                  prefixLabel="code"
                  onChange={handleSelectEventDefault}
                  disabled={form.getFieldValue('lock_edit')}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Upload
                listType="picture-card"
                fileList={fileList}
                multiple={false}
                maxCount={1}
                onPreview={handleOpenPreviewImage}
                onChange={handleChange}
                beforeUpload={handleUpload}
                showUploadList={{ showRemoveIcon: false }}
                style={{ width: '100%' }}>
                {fileList.length >= 8 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
              <Modal
                visible={previewImage.modalVisible}
                title="Previzualização do documento"
                footer={null}
                onCancel={handleClosePreviewImage}>
                <img alt="example" style={{ width: '100%' }} src={previewImage.url} />
              </Modal>
            </Col>
            <Col span={12}>
              <Form.Item name="historic_id" label="Histórico padrão">
                <FormSelect
                  onSearch={(value: string) => searchHistoric(value)}
                  defaultValue={form.getFieldValue('historic')?.description}
                  items={historicList}
                  field={form.getFieldValue('historic')}
                  prefixLabel="code"
                  disabled={form.getFieldValue('lock_edit')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label="Tipo de lançamento" rules={[{ required: true }]}>
                <Select placeholder="Tipo de lançamento" onChange={(value) => setEventType(value)} disabled={form.getFieldValue('lock_edit')}>
                  <Option value={1}>Um débito para vários créditos</Option>
                  <Option value={2}>Um crédito para vários débitos</Option>
                  <Option value={3}>Um débito para um crédito</Option>
                  <Option value={4}>Vários débitos para vários créditos</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="date" label="Data" rules={[{ required: true }]}>
                <Input
                  onChange={(event) =>
                    formatDateToBr(event.currentTarget.value, (result) =>
                      form.setFieldsValue({ date: result }),
                    )
                  }
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="amount" label="Valor" rules={[{ required: true }]}>
                <Input onChange={(event) => handleChangeEventAmount(event.currentTarget.value)} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="document" label="Número do documento">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="document_type_id" label="Tipo de documento">
                <FormSelect
                  onSearch={(value: string) => searchDocumentType(value)}
                  defaultValue={form.getFieldValue('document_type')?.description}
                  items={documentTypeList}
                  field={form.getFieldValue('document_type')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="payment_type_id" label="Tipo de pagamento">
                <FormSelect
                  onSearch={(value: string) => searchPaymentType(value)}
                  defaultValue={form.getFieldValue('payment_type')?.description}
                  items={paymentTypeList}
                  field={form.getFieldValue('payment_type')}
                  prefixLabel="code"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cost_center_id" label="Centro de custo">
                <FormSelect
                  onSearch={(value: string) => searchCostCenter(value)}
                  defaultValue={form.getFieldValue('cost_center')?.description}
                  items={costCenterList}
                  field={form.getFieldValue('cost_center')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="historic_complement" label="Histórico Complementar">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="origin" label="Origem" rules={[{ required: true }]}>
                <Select placeholder="Origem do lançamento" disabled={form.getFieldValue('lock_edit')}>
                  <Option key={1} value={1} disabled>
                    Importação XML
                  </Option>
                  <Option key={2} value={2} disabled>
                    Importação SPED
                  </Option>
                  <Option key={3} value={3}>
                    Manual
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="conciliated" label="Conciliado" valuePropName="checked">
                <Switch size="small" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form form={formAccounts} onFinish={handlerAddEventAccount} autoComplete="off" layout="vertical">
          <Row>
            <Col span={24} style={{ backgroundColor: '#eee', padding: '5px' }}>
              <table style={{ textAlign: 'left', width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ padding: 5, width: '55%' }}>
                      <Form.Item name="account_plan_id" label="Conta" rules={[{ required: true }]}>
                        <Select
                          labelInValue={false}
                          placeholder="Digite para buscar..."
                          notFoundContent={
                            !accountPlanList ? (
                              <Spin size="small" />
                            ) : (
                              <div>Nenhum dado encontrado</div>
                            )
                          }
                          filterOption={false}
                          onSearch={(value) => searchAccountPlan(value)}
                          style={{ width: '100%' }}
                          showSearch
                          allowClear>
                          {accountPlanList.map((item: any) => (
                            <Option key={item.id} value={item.id}>
                              {item.account} - {item.description}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ padding: 5, width: '20%' }}>
                      <Form.Item name="type" label="Tipo" rules={[{ required: true }]}>
                        <Select style={{ width: '100%' }}>
                          <Option value="debit" disabled={eventType === 1}>
                            Débito
                          </Option>
                          <Option value="credit" disabled={eventType === 2}>
                            Crédito
                          </Option>
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ padding: 5, width: '20%' }}>
                      <Form.Item name="amount" label="Valor" rules={(form.getFieldValue('tyoe') === 3) ? [] : [{ required: true }]}>
                        <Input placeholder="R$ 0,00" />
                      </Form.Item>
                    </td>
                    <td style={{ padding: 5 }}>
                      <Button type="primary" htmlType="submit">
                        <PlusOutlined />
                      </Button>
                    </td>
                  </tr>
                  {(eventAccountList || []).map((item: any) => (
                    <tr>
                      <td style={{ padding: 5 }}>{item?.account_plan?.code} - {item?.account_plan?.description}</td>
                      {/* <td style={{ padding: 5 }}>{item?.historic?.description}</td> */}
                      <td style={{ padding: 5 }}>
                        <Tag color={item.type === 'credit' ? 'green' : 'red'}>
                          {item.type === 'credit' ? 'CRÉDITO' : 'DÉBITO'}
                        </Tag>
                      </td>
                      <td style={{ padding: 5 }}>
                        {formatCurrencyToBr((item.type == 'debit') ? Math.abs(item.amount) * -1 : Math.abs(item.amount))}
                      </td>
                      <td style={{ padding: 5 }}>
                        <Button
                          type="default"
                          htmlType="button"
                          style={{ background: 'red' }}
                          onClick={() => handlerDeleteEventAccountList(item)}>
                          <DeleteOutlined style={{ color: '#fff' }} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Form>
        <Row gutter={15} style={{ marginTop: 15 }}>
          <Col span={4}>
            <Button
              onClick={() => setShowDrawer(false)}
              style={{ marginRight: 8 }}
              block
              htmlType="button">
              Cancelar
            </Button>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              htmlType="button"
              block
              loading={loading}
              onClick={() => form.submit()}>
              Salvar
            </Button>
          </Col>
        </Row>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
      <Modal
        title="Visualização do documento"
        visible={modalPhotoVisible}
        onOk={() => setModalPhotoVisible(false)}
        onCancel={() => setModalPhotoVisible(false)}>
        <img src={modalPhotoUrl} alt="Ilustração" width="100%" />
      </Modal>
    </LayoutFull>
  );
}
