import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Table,
  message,
  notification,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CustomPagination from '../../components/CustomPagination';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

type FormFilter = {
  name: string;
  cellphone: string;
  birthday: any;
};

export function CandidatePersonal() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [dataTableItem, setDataTableItem] = useState<any>({});

  const [filters, setFilters] = useState<FormFilter>({} as FormFilter);

  const [form] = Form.useForm();

  const columns: any[] = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: 'Telefone',
      key: 'cellphone',
      dataIndex: 'cellphone',
      sorter: (a: any, b: any) => a.cellphone.length - b.cellphone.length,
    },
    {
      title: 'Data de nascimento',
      key: 'birthday',
      dataIndex: 'birthday',
      sorter: (a: any, b: any) => a.birthday.length - b.birthday.length,
      render: (value: string, record: any) => value && moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button type="link" onClick={() => hidrateFormWithValues(record)}>
            {' '}
            Visualizar
          </Button>
        </Space>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { ...filters, ...{ page: currentPage } };
      const { data } = await api.get('candidate-personal', { params });
      setDataTable(data);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, filters]);

  const handlerFilter = async (values: any) => {
    let periodFilter;
    if (values.birthday) {
      const dateStart = values.birthday;
      periodFilter = dateStart && moment(dateStart).format('YYYY-MM-DD');
    }
    setCurrentPage(1);
    setFilters({
      name: values.name || undefined,
      cellphone: values.cellphone || undefined,
      birthday: periodFilter || undefined,
    });
  };

  const hidrateFormWithValues = useCallback(async (values: any) => {
    setShowDrawer(true);
    setDataTableItem(values);
    setErrors([]);
  }, []);

  const saveOrCreate = async (values: any) => {
    setLoading(true);
    if (values.id) {
      try {
        await api.put(`candidate-personal/${values.id}`, values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro atualizado com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      delete values.id;
      try {
        await api.post('candidate-personal', values);
        setShowDrawer(false);
        notification.open({
          message: 'Sucesso',
          description: 'Registro inserido com sucesso',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        });
        getData();
      } catch (err: any) {
        setErrors(err?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutFull headerTitle="Dados do candidato">
      <Form onFinish={handlerFilter} autoComplete="off" layout="vertical">
        <Row gutter={15}>
          <Col span={4}>
            <Form.Item name="name" label="Nome">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="cellphone" label="Telefone">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="birthday" label="Data de nascimento">
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="default"
              htmlType="submit"
              loading={loading}
              icon={<SearchOutlined />}
              style={{ marginTop: 30 }}>
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={() => Math.random()}
        loading={loading}
        pagination={false}
        tableLayout="auto"
      />
      <CustomPagination
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        currentPage={currentPage}
        dataTableLength={Boolean(dataTable.length)}
      />
      <Drawer
        title="Dados do candidato"
        width={720}
        onClose={() => setShowDrawer(false)}
        maskClosable={false}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={saveOrCreate}
          initialValues={{
            id: '',
          }}>
          <Row gutter={15}>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={15}>
              <div
                style={{
                  backgroundColor: '#eee',
                  padding: '5px 10px',
                  margin: '0 0 15px 0',
                  borderRadius: '5px',
                }}>
                <label style={{ fontSize: '12px' }}>Nome</label>
                <br />
                <span>{dataTableItem?.name}</span>
              </div>
            </Col>
            <Col span={7}>
              <div
                style={{
                  backgroundColor: '#eee',
                  padding: '5px 10px',
                  margin: '0 0 15px 0',
                  borderRadius: '5px',
                }}>
                <label style={{ fontSize: '12px' }}>Telefone</label>
                <br />
                <span>{dataTableItem?.cellphone}</span>
              </div>
            </Col>
            <Col span={7}>
              <div
                style={{
                  backgroundColor: '#eee',
                  padding: '5px 10px',
                  margin: '0 0 15px 0',
                  borderRadius: '5px',
                }}>
                <label style={{ fontSize: '12px' }}>Data de nascimento</label>
                <br />
                <span>{moment(dataTableItem?.birthday).format('DD/MM/YYYY')}</span>
              </div>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Button
                onClick={() => setShowDrawer(false)}
                style={{ marginRight: 8 }}
                block
                htmlType="button">
                Voltar
              </Button>
            </Col>
          </Row>
        </Form>
        <ul style={{ listStyle: 'none' }}>
          {errors?.length > 0 &&
            errors.map((item: any) => (
              <li>
                <Alert message={item} showIcon type="error" style={{ marginTop: 10 }} />
              </li>
            ))}
        </ul>
      </Drawer>
    </LayoutFull>
  );
}
